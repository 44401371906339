export function RESET_PASS(context) {
    const options = {
        width: '400px',
        data: {
            title: '¿Estás seguro que deseas restabelcer tu contraseña?',
            buttons: [
                {
                    text: 'Cancelar',
                    action: () => {
                        context.dialog.closeAll();
                    },
                },
                {
                    text: 'Ok',
                    action: () => {
                        context.resetPin();
                    },
                },
            ],
        },
    };
    return options;
}

export function RESET_PASS_SUCCESS(context) {
    const options = {
        width: '450px',
        data: {
            title: 'Éxito',
            subtitle: 'Hemos enviado un correo con las instrucciones para restablecer tu contraseña.',
            buttons: [
                {
                    text: 'Ok',
                    action: () => {
                        context.dialog.closeAll();
                    },
                },
            ],
        },
    };
    return options;
}

export function WRONG_PASS(context) {
    const options = {
        width: '450px',
        data: {
            title: '¡Ups!',
            subtitle: 'Parece que tu contraseña es incorrecta.',
            buttons: [
                {
                    text: 'Ok',
                    action: () => {
                        context.dialog.closeAll();
                    },
                },
            ],
        },
    };
    return options;
}
