import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { ShareButtonsModule } from '@ngx-share/buttons';
import { NgxCurrencyModule } from 'ngx-currency';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { MaterialModule } from '../shared/modules/material.module';
import { GlobalHeaderModule } from '../shared/components/global-header/global-header.module';
import { RouterModule } from '@angular/router';
import { SharedPopupModule } from '../shared/components/shared-popup/shared-popup.module';
import { BlogModule } from '../modules/extras/blog/blog.module';
import { PinLoginComponent } from './pin-login/pin-login.component';
import { AppLoginComponent } from './app-login/app-login.component';
import { AppLobbyComponent } from './app-lobby/app-lobby.component';
import { AttitudeQuestionsPopupComponent } from '../shared/components/AttitudeQuestionsPopup/attitude-questions-popup.component';

@NgModule({
    declarations: [
        PinLoginComponent,
        AppLoginComponent,
        AppLobbyComponent,
        AttitudeQuestionsPopupComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        GlobalHeaderModule,
        SharedPopupModule,
        NgxCurrencyModule,
        SwiperModule,
        MatIconModule,
        // FunnelsModule
    ],
  exports: [
    AttitudeQuestionsPopupComponent
  ]
})
export class ToolsModule { }
