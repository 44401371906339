import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { TeamComponent } from './team/team.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPinComponent } from './reset-pin/reset-pin.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-terms/privacy-policy.component';
import { RegisterComponent } from './login/register/register.component';
import { ParentComponent } from './login/register/parent/parent.component';
import { StudentComponent } from './login/register/student/student.component';
import { SchoolComponent } from './login/register/school/school.component';
import { BlogComponent } from './blog/blog.component';
import { ArticleComponent } from './blog/article.component';
import { PaymentsComponent } from './payments/payments.component';
import { PlansComponent } from './plans/plans.component';
import { IntroductionComponent } from './login/introduction/introduction.component';
import { ValidateAccountComponent } from './validate-account/validate-account.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { StoreComponent } from './store/store.component';
import { ProductDetailComponent } from './store/product-detail/product-detail.component';
import { CartComponent } from './store/cart/cart.component';
import { PaymentComponent } from './store/payment/payment.component';
import { PaymentCompleteComponent } from './store/payment-complete/payment-complete.component';
import { InverlingoComponent } from './inverlingo/inverlingo.component';
import { ParentsComponent } from './parents/parents.component';
import { TeachersComponent } from './teachers/teachers.component';
import { GamesComponent } from './games/games.component';
import { GameComponent } from './games/game/game.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { CardComponent } from './login/register/debitCard/card.component';
// import { LoginRedirectComponent } from './login/loginRedirect/loginRedirect.component';
import { SchoolHubComponent } from './school-hub/school-hub.component';
import { SchoolProductComponent } from './school-product/school-product.component';
import { PremiosComponent } from './premios/premios.component';
import { AppLoginComponent } from '../tools/app-login/app-login.component'

import { environment } from '../../environments/environment';


export const HOMEPAGE_ROUTES: Routes = [
    {
        path: '',
        component: ["app"].includes(environment.plataform) ? AppLoginComponent : HomeComponent,
    },
    {
        path: 'escuela/:school',
        component: SchoolHubComponent,
    },
    {
        path: 'escuela/:school/producto/:product_id',
        component: SchoolProductComponent,
    },
    {
        path: 'nosotros',
        component: TeamComponent,
    },
    {
        path: 'games',
        component: GamesComponent,
    },
    {
        path: 'games/:game',
        component: GameComponent,
    },
    {
        path: 'downloads',
        component: DownloadsComponent,
    },
    {
        path: 'blog',
        component: BlogComponent,
    },
    {
        path: 'blog/:blog_name',
        component: ArticleComponent,
    },
    {
        path: 'reset-password/:token',
        component: ResetPasswordComponent,
    },
    {
        path: 'reset-pin/:token',
        component: ResetPinComponent,
    },
    {
        path: 'terminos-y-condiciones',
        component: TermsAndConditionsComponent,
    },
    {
        path: 'politicas-de-privacidad',
        component: PrivacyPolicyComponent,
    },
    {
        path: 'register',
        component: RegisterComponent,
    },
    // {
    //     path: 'login',
    //     component: LoginRedirectComponent,
    // },
    {
        path: 'register/parent',
        component: ParentComponent,
    },
    {
        path: 'register/student',
        component: StudentComponent,
    },
    {
        path: 'register/school',
        component: SchoolComponent,
    },
    {
        path: 'debitCard',
        component: CardComponent,
    },
    {
        path: 'pago',
        component: PaymentsComponent,
    },
    {
        path: 'planes',
        component: PlansComponent,
    },
    {
        path: 'introduction',
        component: IntroductionComponent,
    },
    {
        path: 'validate-account/:email',
        component: ValidateAccountComponent,
    },
    {
        path: 'payment-success',
        component: PaymentSuccessComponent,
    },
    {
        path: 'tienda',
        component: StoreComponent,
    },
    {
        path: 'tienda/producto/:product_id',
        component: ProductDetailComponent,
    },
    {
        path: 'carrito',
        component: CartComponent,
    },
    {
        path: 'carrito/pago',
        component: PaymentComponent,
    },
    {
        path: 'payment-complete',
        component: PaymentCompleteComponent,
    },
    {
        path: 'inverlingo',
        component: InverlingoComponent,
    },
    {
        path: 'padres',
        component: ParentsComponent,
    },
    {
        path: 'maestros',
        component: TeachersComponent,
    },
    {
        path: 'premios',
        component: PremiosComponent,
    }
];
