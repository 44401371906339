import { Component, Input, OnInit, ViewChild, HostListener, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { UserProfileService } from "../../../shared/services/userProfile.service";
import {AttitudeAnswer, StudentService} from "./student.service";

import { environment } from "../../../../environments/environment";
import { AttitudeQuestion, PossibleAnswer } from "../../cms/managements/skills-management/skills-management.service";

export const show_parent_onboarding_key = 'SHOW_PARENT_ONBOARDING';

@Component({
  selector: "app-student-dashboard",
  templateUrl: "./student.component.html",
  styleUrls: ["./student.component.scss"],
})
export class StudentComponent implements OnInit, OnDestroy {
  assetsUrl = '';
  @Input() user;

  @ViewChild('historyCardTitle', { static: false }) historyCardTitle;
  subtitle_width;
  subtitle_interval_id;

  user_name;
  user_streak;
  user_exp;
  user_points;

  level;
  showCurrency = true;
  showHitpoints = false;
  showDropdown = true;
  userLang;

  show_streak = false;
  show_parent_onboarding = false;
  streak_days: any = [];
  day_letters = [
    {'es': 'L', 'en': 'M'},
    {'es': 'M', 'en': 'T'},
    {'es': 'M', 'en': 'W'},
    {'es': 'J', 'en': 'T'},
    {'es': 'V', 'en': 'F'},
    {'es': 'S', 'en': 'S'},
    {'es': 'D', 'en': 'S'},
  ];
  day_colors = {
    'naranja': '#F63',
    'verde': '#86C71E',
    'morado': '#AD009E',
    'rojo': '#FF3764'
  };

  continue_module;
  continue_topic;
  continue_button = true;

  reward = 10;

  continue_topic_name = {
    'es': 'El ciclo del dinero',
    'en': 'The cycle of money'
  };

  continue_module_cover = 'module_1';

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private studentService: StudentService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.historyCardTitle != null) {
      const new_width = this.historyCardTitle.nativeElement.offsetWidth;
      if (this.subtitle_width !== new_width) {
        console.log("new_width: ", new_width, this.subtitle_width);
        this.subtitle_width = new_width;
        clearInterval(this.subtitle_interval_id);
      }
    }
  }

  async ngOnInit() {
    this.assetsUrl = environment.assetsUrl;
    this.userLang = this.userProfileService.getLang();
    console.log("user: ", this.user);

    this.user_name = this.user.name;
    this.user_streak = this.user.streak;
    this.user_exp = this.user.experience;
    this.user_points = this.user.premium_points;

    this.studentService.getStreakWeekdayNumbers().then((data) => {
      this.streak_days = data;
    });

    this.studentService.getContinueModule(this.user.id).then((data) => {
      if (data.error == null) {
        this.continue_module = data.module;
        this.continue_topic = data.topic;
      }
      console.log("continue data:", this.continue_module, this.continue_topic);

      if (this.subtitle_interval_id == null) {
        console.log("created  interval:", this);
        this.subtitle_interval_id = setInterval(() => {
          console.log("interval running:", this);
          if (this.historyCardTitle != null) {
            const new_width = this.historyCardTitle.nativeElement.offsetWidth;
            if (this.subtitle_width !== new_width) {
              console.log("new_width: ", new_width, this.subtitle_width);
              this.subtitle_width = new_width;
              clearInterval(this.subtitle_interval_id);
            }
          }
        }, 10);

        setTimeout(() => {
          clearInterval(this.subtitle_interval_id);
        }, 5000);
      }
    }).catch((error) => {
      this.continue_module = {cover: 'default'};
      this.continue_topic = {name: {
        'es': 'No hay modulo siguiente',
        'en': 'No next module'
      }};
      this.continue_button = false;
    });

    this.show_parent_onboarding = !this.user.parent_account && localStorage.getItem(show_parent_onboarding_key) === 'true';
  }

  ngOnDestroy() {
    console.log("destroy interval: ", this.subtitle_interval_id);
    clearInterval(this.subtitle_interval_id);
  }

  handleParentOnboardingCheckbox() {
    this.show_parent_onboarding = false;

  }

  getDayColor() {
    return this.day_colors[this.user.avatar];
  }

  dayInStreak(index) {
    return this.streak_days.includes(index);
  }

  toggleStreak() {
    this.show_streak = !this.show_streak;
  }

  closeParentOnboarding() {
    this.show_parent_onboarding = false;
    localStorage.setItem(show_parent_onboarding_key, 'false');
  }

  goToRoute(): void {
    if (this.user.account_type !== "Free") {
      this.router.navigate(["/courses"]);
    } else {
      this.router.navigate(["/misc/buy-premium"]);
    }
    this.level = this.user.level;
    this.showCurrency = this.level === "secundaria" ? true : false;
  }

  goToContinue(): void {
      if (this.continue_module.module_number != null && this.continue_topic.topic_number != null) {
        this.router.navigate([
          "/courses", this.continue_module.module_number,
          'topics', this.continue_topic.topic_number,
          'topic_dashboard'
        ]);
      } else {
        console.log("no continue possible");
      }
  }

  goTo(route): void {
    this.router.navigate(route);
}

  shortName(name) {
    const split_name = name.split(" ");
    return split_name[0];
  }
}
