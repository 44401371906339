import {Component, OnInit, Input, ElementRef, Renderer2 } from "@angular/core";
import {AttitudeQuestion, PossibleAnswer} from "../../../modules/cms/managements/skills-management/skills-management.service";
import {AttitudeQuestionsPopupService} from "./attitude-questions-popup.service";
import {AttitudeAnswer} from "../../../modules/dashboard/student/student.service";

interface ResponseAttitudeQuestion extends AttitudeQuestion {
  possible_answers: { text_content: { en: string, es: string }, points_to_award: number, selected: boolean }[];
  is_being_answered: boolean;
}

@Component({
  selector: 'app-attitude-questions-popup',
  templateUrl: './attitude-questions-popup.component.html',
  styleUrls: ['./attitude-questions-popup.component.scss'],
})
export class AttitudeQuestionsPopupComponent implements OnInit {
  constructor(
    private readonly aqpS: AttitudeQuestionsPopupService,
    private self: ElementRef,
    private renderer: Renderer2
  ) {
  }

  @Input() userLang = 'es';

  pending_attitude_questions: ResponseAttitudeQuestion[] = [];
  ngOnInit() {
    this.fetchPendingAQ();
  }

  async fetchPendingAQ() {
    this.pending_attitude_questions = (await this.aqpS.getPendingAttitudeQuestions() as AttitudeQuestion[]).map(aq => {
      aq.possible_answers.forEach(po => {
        po['selected'] = false;
        po['is_being_answered'] = false;
      });

      return (aq as ResponseAttitudeQuestion);
    });

    console.debug(this.pending_attitude_questions);
    if (this.pending_attitude_questions.length > 0) {
      this.setHostStyles();
    }
  }

  setHostStyles() {
    const hostElement = this.self.nativeElement;

    this.renderer.setStyle(hostElement, 'position', 'fixed');
    this.renderer.setStyle(hostElement, 'inset', '0');
    this.renderer.setStyle(hostElement, 'z-index', '13');
  }

  setDisplayNone() {
    const hostElement = this.self.nativeElement;

    this.renderer.setStyle(hostElement, 'display', 'none');
  }

  selectThisPossibleAnswer(aq: ResponseAttitudeQuestion, pa: PossibleAnswer) {
    aq.possible_answers.forEach(a => {
      a.selected = a === pa;
    });
  }

  ctaShouldBeDisabled(aq: ResponseAttitudeQuestion) {
    return !aq.possible_answers.some(pa => pa.selected) || aq.is_being_answered;
  }

  attitudeAnswerErrors: { uuid_of_aq: string, message: { en: string; es: string; }}[] = [];
  getAttitudeAnswerErrors(aq: ResponseAttitudeQuestion) {
    return this.attitudeAnswerErrors.filter(aa => aa.uuid_of_aq === aq.uuid);
  }

  async answerAttitudeQuestion(aq: ResponseAttitudeQuestion) {
    this.attitudeAnswerErrors = [];

    aq.is_being_answered = true;

    const selected_possible_answer = aq.possible_answers.find(po => po.selected);

    if (!selected_possible_answer) {
      this.attitudeAnswerErrors.push({
        uuid_of_aq: aq.uuid,
        message: {
          en: 'You must select an answer.',
          es: 'Debes de elegir una respuesta'
        }
      });

      aq.is_being_answered = false;

      return;
    }

    const attitude_answer: AttitudeAnswer = {
      text_content: selected_possible_answer.text_content,
      belongs_to_question: aq.uuid,
    };

    try {
      await this.aqpS.postAttitudeAnswer(attitude_answer);

      const indexOf = this.pending_attitude_questions.indexOf(aq);
      this.pending_attitude_questions.splice(indexOf, 1);
    } catch (err) {
      console.error(err);

      this.attitudeAnswerErrors.push({
        uuid_of_aq: aq.uuid,
        message: {
          en: 'We had an unexpected error.',
          es: 'Tuvimos un error inesperado.'
        }
      });
    }

    if (this.pending_attitude_questions.length === 0) {
      this.setDisplayNone();
    }

    aq.is_being_answered = false;
  }
}
