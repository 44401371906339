import { Component } from '@angular/core';
import { environment } from '../../environments/environment';


@Component({
    templateUrl: './homepage.component.html',
})
export class HomePageComponent {
    isApp(){
        return ["app"].includes(environment.plataform)
    }
}
