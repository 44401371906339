import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class AppLobbyService {
    private requestInProgress = false;

    constructor(private http: HttpClient) {}

    verifyCode(code): Promise<any> {
        const params = {
            code
        }
        return this.http.post(`${environment.apiUrl}/groups/validate_group_code`, params).toPromise();
    }

    login(user_id, code): Promise<any> {
        const params = {
            user_id,
            code
        }
        return this.http.post(`${environment.apiUrl}/lobby_login`, params).toPromise();
    }
}
