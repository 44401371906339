import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedPopupComponent } from '../../shared/components/shared-popup/shared-popup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPasswordService } from './reset-password.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    dialogRef;

    password: string;
    password_confirm: string;
    error: String[] = [];
    resetToken: string;

    selected_lang = 'es'

    constructor(
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private resetPasswordService: ResetPasswordService,
        private router: Router
    ) {}

    ngOnInit() {
        const resetToken = this.activatedRoute.snapshot.paramMap.get('token');
        // const options = {
        //     width: '400px',
        //     disableClose: true,
        //     data: {
        //         title: 'Cambio de contraseña',
        //         content: {
        //             type: 'input-option',
        //             options: [
        //                 {
        //                     text: 'Contraseña',
        //                     value: '',
        //                     disabled: false,
        //                     type: 'password'
        //                 },
        //                 {
        //                     text: 'Repetir contraseña',
        //                     value: '',
        //                     disabled: false,
        //                     type: 'password'
        //                 }
        //             ]
        //         },
        //         error: undefined,
        //         buttons: [
        //             {
        //                 text: 'Guardar',
        //                 action: () => {
        //                     const password = options.data.content.options[0].value;
        //                     const rePassword = options.data.content.options[1].value;
        //                     if (password !== rePassword) {
        //                         options.data.error = 'Las contraseñas no coinciden';
        //                     } else {
        //                         this.resetPasswordService.resetPassword(password, resetToken).then(
        //                             data => {
        //                                 this.passwordUpdatedSuccess();
        //                             },
        //                             error => {
        //                                 this.passwordUpdatedError();
        //                             }
        //                         );
        //                     }
        //                 }
        //             }
        //         ]
        //     }
        // };

        // this.dialog.open(SharedPopupComponent, options);
    }

    setPassword(event){
        this.password = event.target.value
    }

    setPasswordConfirm(event){
        this.password_confirm = event.target.value
    }

    sendResetPassword(){
        if (this.password !== this.password_confirm) {
            this.error.push("")
        } else {
            this.resetPasswordService.resetPassword(this.password, this.resetToken).then(
                data => {
                    this.passwordUpdatedSuccess();
                },
                error => {
                    this.passwordUpdatedError();
                }
            );
        }
    }

    passwordUpdatedSuccess(): void {
        this.dialog.closeAll();
        this.dialog.open(SharedPopupComponent, {
            width: '500px',
            data: {
                title: 'Tu contraseña se ha actualziado exitosamente',
                buttons: [
                    {
                        text: 'Ok',
                        action: () => {
                            this.router.navigate(['/app-login']);
                            this.dialog.closeAll();
                        }
                    }
                ]
            }
        });
    }

    passwordUpdatedError(): void {
        this.dialogRef = this.dialog.open(SharedPopupComponent, {
            width: '500px',
            data: {
                title: 'Lo sentimos no pudimos actualizar tu contraseña.',
                subtitle: 'Si el problema persiste, por favor contacte al equipo de soporte.',
                buttons: [
                    {
                        text: 'Ok',
                        action: () => {
                            this.dialogRef.close();
                        }
                    }
                ]
            }
        });
    }
}
