import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { AttitudeAnswer } from "../../../modules/dashboard/student/student.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AttitudeQuestionsPopupService {
  constructor(private http: HttpClient) {}

  async getPendingAttitudeQuestions() {
    return await this.http.get(`${environment.apiUrl}/attitude_questions/my_pending_questions`, {}).toPromise();
  }

  async postAttitudeAnswer(aa: AttitudeAnswer) {
    return await this.http.post(`${environment.apiUrl}/attitude_questions/answer`, { attitude_answer: aa }).toPromise();
  }
}
