import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { UserProfileService } from '../../shared/services/userProfile.service';

import { AppLobbyService } from './app-lobby.service';
import { SharedPopupComponent } from '../../shared/components/shared-popup/shared-popup.component';
import * as appDialogs from './app-lobby.dialogs';
import { UserCredentials } from '../../shared/models/user.model';
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ResetPasswordService } from '../../homepage/reset-password/reset-password.service';
import { show_parent_onboarding_key } from "../../modules/dashboard/student/student.component";
import { environment } from '../../../environments/environment.prod';

@Component({
    selector: 'app-app-lobby',
    templateUrl: './app-lobby.component.html',
    styleUrls: ['./app-lobby.component.scss'],
})
export class AppLobbyComponent {
    error;
    enrollmentId: string;
    enrollmentIdError = false;
    codeError = false;
    userCredentials = new UserCredentials();
    selected_lang = "es";
    inside = false;
    code;
    users = [];
    assetsUrl = environment.assetsUrl

    constructor(private appLobbyService: AppLobbyService, private router: Router, private dialog: MatDialog,
        private userProfileService: UserProfileService,
        private activatedRoute: ActivatedRoute,
        private resetPasswordService: ResetPasswordService,
        private authService: AuthenticationService,
        ) {
    }

    setCode(event){
        this.code = event.target.value.trim().toUpperCase()

        if(this.code.length > 6){
            this.code = this.code.substr(0,6)
        }

        event.target.value = this.code
    }

    close(){
        this.router.navigate(['/app-login']);
    }

    enter(){
        this.appLobbyService.verifyCode(this.code).then((resp)=>{
            console.log('resp:', resp)
            this.users = resp.users

            if(this.users.length > 0){
                this.inside = true
            }
        })
    }

    login(user){
        this.appLobbyService.login(user.id, this.code).then((resp)=>{
            console.log("resp: ", resp)
            this.userProfileService.setUserType(user.user_type);
            this.userProfileService.setUserRole(user.role);
            this.userProfileService.setUserId(user.id);
            this.userProfileService.setAuthToken(user.auth_token);
            this.userProfileService.setAccountType(user.account_type);
            this.userProfileService.removeParentToken();
            this.userProfileService.getUserProfile().then((user) => {
                this.router.navigate(['/dashboard']);
            });
        })
    }
}
