import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

export interface AttitudeAnswer {
  text_content: { en: string; es: string; };
  belongs_to_question: string;
}

@Injectable({
    providedIn: 'root',
})
export class StudentService {
    constructor(private http: HttpClient) {}

    getContinueModule(user_id): Promise<any> {
        return this.http.post(`${environment.apiUrl}/modules/get_continue_module`, {user_id}).toPromise();
    }
    getStreakWeekdayNumbers() {
        return this.http.get(`${environment.apiUrl}/profile/get_streak_weekday_numbers`, {}).toPromise();
    }
}
