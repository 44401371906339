import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

interface Parent {
  name: string;
  nickname: string;
  birthday: string;
  avatar: 'azul'|'naranja'|'morado'|'verde'|'rosa';
  pin?: string;
  pin_confirmation?: string;
  gender: string;
  email: string;
  password: string;
  password_confirmation: string;
  user_type: string;

  child_accounts?: string[];
}

@Injectable({
    providedIn: 'root',
})
export class RegisterService {
    private requestInProgress = false;

    constructor(private http: HttpClient) {}

    registerParent(parent: Parent): Promise<any> {
        parent.email = parent.email.toLowerCase();

        if (!this.requestInProgress) {
            this.requestInProgress = true;
            return this.http
                .post<any>(`${environment.apiUrl}/parents`, { parent })
                .pipe(
                    tap(
                        () => (this.requestInProgress = false),
                        () => (this.requestInProgress = false)
                    )
                )
                .toPromise();
        }
    }

    registerStudent(student): Promise<any> {
        student.email = student.email.toLowerCase();

        if (!this.requestInProgress) {
            this.requestInProgress = true;
            return this.http
                .post<any>(`${environment.apiUrl}/students`, { student })
                .pipe(
                    tap(
                        () => (this.requestInProgress = false),
                        () => (this.requestInProgress = false)
                    )
                )
                .toPromise();
        }
    }
}
