export const BLOGS = [
    {
        id: 'tres-errores-al-tener-una-cuenta-de-ahorro-para-niños',
        title: 'Evita estos 3 errores al tener una cuenta de ahorro para niños',
        image: 'assets/images/blog/img13.jpg',
        description:
            '¿Tienes dudas de cómo utilizar una cuenta de ahorro para niños para enseñarle a tus hijos a usar el dinero? Te decimos cómo usar una sin equivocarte.',
        content: ` <p> Una cuenta de ahorro para niños es un excelente instrumento para que ellos aprendan a manejar el dinero desde pequeños. No se trata de dejarlos solos con este instrumento, sino de guiarlos de la mano a través de un buen ejemplo y de lecciones que les permitan entender cómo funcionan las finanzas personales.</p>
        <p> Los expertos coinciden en que enseñarles a manejar el dinero es algo que hay que hacer cuanto antes. Warren Buffett, el mayor multimillonario hecho por invertir en bolsa, asegura que el error más grande relacionado con este recurso que los padres cometen con sus hijos, es esperar hasta que son adultos para enseñarles a usarlo y <a href="https://www.cnbc.com/2019/07/30/warren-buffett-this-is-the-no-1-mistake-parents-make-when-teaching-kids-about-money.html" target="_blank">no empezar desde preescolar</a>.</p>
        <p>Si bien una alcancía puede ser una buena opción para guardar sus recursos al principio, abrirles una cuenta de ahorro para niños es un gran paso para continuar su educación financiera.</p> 
        <p>“Enseñarles sobre dinero mientras son jóvenes abriéndoles una cuenta de ahorro es una gran manera de ayudarlos a madurar y a convertirse en financieramente responsables”, sostiene <a href="https://www.bankrate.com/banking/savings/should-your-child-have-a-savings-account/" target="_blank">un texto de Bankrate</a> al respecto.</p> 
        <p>Es importante escoger un instrumento especializado en ahorro para niños porque los que existen en el mercado están exentos de comisiones. Además de que en algunos casos les permiten elegir el diseño de los plásticos.</p>
        <p>Para sacarle mayor provecho a esta herramienta, te dejamos los 3 errores que tienes que evitar cuando les enseñes a tus hijos a usarla.</p> 
        <h2>No hablar con ellos del dinero que está en esa cuenta</h2>
        <p>Hablar, dar un buen ejemplo y dejar que ellos practiquen son tres de las máximas –según varios estudios científicos– para que ellos aprendan bien el uso de este recurso. Por estas razones, es esencial sentarse con los niños a hablar de por qué existe esa cuenta, cómo funciona y de qué manera entrará y saldrá dinero de ahí. No esperes a que ellos crezcan para hablarles de este tema y aprovecha cualquier momento para hacerlo.</p>
        <p>Según la <a href="https://www.troweprice.com/corporate/en/press/t--rowe-price--parents-are-likely-to-pass-down-good-and-bad-fina0.html" target="_blank">encuesta anual de la firma de inversiones, T. Rowe Price</a>, los padres que discuten temas financieros con sus niños al menos una vez a a la semana son significativamente más propensos a tener niños que son “inteligentes respecto al dinero”. ¡Es hora de hablar! <a href="https://inverkids.mx/blog/hablar-de-dinero-con-ni%C3%B1os" target="_blank">Aquí</a> te decimos más razones por las que hablar con ellos desde pequeños hará la diferencia.</p> 
        <h2>No dejar que tomen decisiones sobre el monto que se guarda ahí</h2>
        <p>Puede generarte un poco de dudas la idea de que tu hijo decida sobre este dinero. Sin embargo, es importante que lo haga para que empiece a practicar.</p>
        <p>Dejar que los niños decidan respecto al dinero hace que lo aprendan a manejar mejor. Los niños a los que los padres les dieron la oportunidad de tomar decisiones relacionadas con este tema lo gastaron menos, mintieron menos sobre en qué lo gastaron y esperaron menos a que sus padres les compraran cosas, según la misma encuesta de T. Rowe Price.</p>
        <p>Por supuesto, no se trata solo de que haga compras de arriba a abajo sin consultarlo contigo. Déjalos que tomen decisiones es importante pero siempre bajo supervisión.</p>
        <h2>No permitirles equivocarse</h2>
        <p>Si hablaron al respecto de cierta decisión financiera, tú no estás de acuerdo pero él insiste en tomarla, déjalo equivocarse. Esa es una de las mejores formas en las que él va a aprender el valor del dinero. Una vez cometido el error, platiquen de ello para analizar qué es lo que salió mal.</p>
        <p>Sin embargo, no repares sus malas decisiones. Hacerlo solo le enseñará a tener poca responsabilidad sobre sus acciones. Si tu hijo decidió gastarse sus ahorros de la manera no más adecuada, enséñalo a esperar a que se vuelvan a acumular los recursos. Si no lo haces, él no aprenderá el valor de la paciencia y de saber esperar para conseguir lo que desea.</p>
        <p>Para evitar al máximo las equivocaciones, establece junto con ellos <a href="https://inverkids.mx/blog/metas-financieras-para-lograr-lo-que-sue%C3%B1as" target="_blank">metas financieras</a>. Esto hará que ellos puedan ahorrar con un objetivo y puedan desviarse menos del buen camino.</p>
        <div class='orange-line'></div>
        <p class='bold'>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre?</p>
        <p>Descarga aquí la app Inverkids, te compartiremos el conocimiento necesario a ti y a tus hijos, para que aprendan a gestionar su dinero, establecer metas financieras y aumentar ahorros a través de la asignación de tareas y recompensas económicas.</p>
        <p>Y si quieres conocer más de nuestros programas, entra a <a class="link" href="www.inverkids.mx">www.inverkids.mx</a></p>`,
    },
    {
        id: 'Enseñarle-a-ahorrar-a-los-niños',
        title: 'Enseñarle a ahorrar a los niños: ¡Así se logra!',
        image: 'assets/images/blog/img12.jpg',
        description:
            'Ahorrar hace vivir mejor y permite alcanzar aquello que se ha soñado. Por eso, enseñarle a ahorrar a los niños es algo que hay que hacer cuanto antes.',
        content: `<p>Ahorrar hace vivir mejor y permite –con el tiempo y la constancia necesaria– alcanzar aquello que se ha soñado. De esta manera, enseñarle a los niños este hábito cuanto antes es algo que va a formarlos para que de adultos puedan estar más preparados para el mundo y, de paso, puedan ser más felices.  Es esencial enseñarle a ahorrar a los niños es algo que hay que hacer cuanto antes.</p>
        <p><a href="https://mascdn.azureedge.net/cms/mas-habit-formation-and-learning-in-young-children-executive-summary.pdf" target="_blank">Según un estudio de la Universidad de Cambridge</a>, debido al deseo de entender el ambiente que los rodea, los pequeños se forman actitudes sobre el dinero desde muy temprana edad. Por ello, enseñarles buenos hábitos lo antes posible es el origen de un adulto que administra bien sus finanzas y que tiene la posibilidad de alcanzar más fácilmente aquello que desea.</p>
        <p>En este sentido, los padres son la principal fuente de información para ellos y es importante aprovecharlo. El disfrute de hacer algo con ellos y el sentimiento de sentirse ‘mayor’ participando en actividades relacionadas con el dinero, proveen la motivación suficiente para desarrollar este tipo de hábitos, señala este mismo estudio.</p>
        <p>Además, enseñarle a ahorrar a los niños formará seres humanos más felices. <a href="https://www.cnbc.com/2019/10/10/study-millennials-who-buy-less-and-save-more-are-happier.html" target="_blank">Un estudio de la Universidad de Arizona</a> encontró que aquellos que llevaban un presupuesto, compraban menos y ahorraban más, tenían mayores niveles de satisfacción. Es decir, que a la larga ahorrar hace feliz y genera un mayor sentimiento de bienestar.</p>
        <p>Pero, ¿por dónde empezar a enseñarle a ahorrar a los niños? Trazamos la ruta que te ayudará a que ellos aprendan a hacerlo lo antes posible.</p>
        <h2>Dales la oportunidad de hacerlo por sí mismos</h2>
        <p>Varios estudios han demostrado que una de las maneras más efectivas para que ellos aprendan a manejar el dinero es darles la oportunidad de que ellos lo administren. De esta manera, tendrán el poder de decisión de qué hacer con este y de equivocarse.</p>
        <p>Decide junto con ellos qué cantidad vas a darles de mesada.</p>
        <p>No se trata de darles dinero y dejar que ellos experimenten en solitario, sino de acompañar esta actividad con conversaciones sobre una buena administración.</p>
        <h2>Enséñales los conceptos básicos</h2>
        <p>Ahora bien, ¿qué les tienes que enseñar cuando les des este domingo?</p>
        <p>La especialista en finanzas personales, <a href="https://www.youtube.com/watch?v=1gd2jwnM-bE" target="_blank">Sonia Sánchez Escuer</a>, enlista de lo conceptos clave que los padres deben enseñarle a sus hijos sobre el dinero:</p>
        <ul><li>No es bueno ni malo sino que es un instrumento de intercambio.</li><li>Para obtener beneficios de este hay que aprender a esperar.</li><li>Manejarlo implica una responsabilidad al tener que decidir en qué se va a gastar.</li><li>Y lo más importante: que cuando reciban dinero, tienen que apartar una parte.</li></ul>
        <p>En esta conversación, decidan cuánto dinero se va a destinar al ahorro. En general, los especialistas recomiendan que se dedique al menos 10% del monto, pero habla este tema y llega a un acuerdo con ellos. </p>
        <h2>Establece metas financieras con tus hijos</h2>
        <p>A la hora de darles su domingo necesitas hablar de lo importante que es que ellos guarden una parte para gastárselo en un futuro y que les expliques que la gratificación que van a recibir no llegará de manera instantánea.</p>
        <p>Este es un concepto muy importante de enseñar a los niños, porque aprenderán a tener paciencia y a trabajar de manera constante por lo que quieren lograr.</p>
        <p>Para ello, también es necesario establecer metas financieras con ellos. <a href="https://inverkids.mx/blog/metas-financieras-para-lograr-lo-que-sue%C3%B1as" target="_blank">Tener metas financieras</a> es lo que va a permitir que aprendan a ahorrar de manera efectiva.</p>
        <p>Ahorrar no se les hará tan pesado si piensan en el próximo juguete, videojuego o tableta electrónica que podrán comprarse, así que ayúdales a ponerle nombre y fecha a aquello en lo que se va a emplear ese ahorro. Esto hará que ellos entiendan la importancia de saber esperar y tener paciencia para alcanzar aquello que desean. Puedes también darles incentivos de ahorro a través de la fijación de metas. Por cierta cantidad que ellos logren acumular, tú vas a poner otro tanto. De esta manera es mucho más probable que permanezcan motivados y que sigan por este camino del ahorro.</p>
        <div class='orange-line'></div>
        <p class='bold'>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre?</p>
        <p>Descarga aquí la app Inverkids, te compartiremos el conocimiento necesario a ti y a tus hijos, para que aprendan a gestionar su dinero, establecer metas financieras y aumentar ahorros a través de la asignación de tareas y recompensas económicas.</p>
        <p>Y si quieres conocer más de nuestros programas, entra a <a class="link" href="www.inverkids.mx">www.inverkids.mx</a></p>`,
    },
    {
        id: 'claves-para-perderle-el-miedo-a-que-los-niños-manejen-su-cuenta-de-ahorro',
        title: 'Consejos para que los niños manejen su cuenta de ahorro',
        image: 'assets/images/blog/img11.jpg',
        description:
            '¿Y si se lo gasta todo? ¿Y si comete errores? Que los niños manejen su propia cuenta de ahorros es una excelente forma de que aprendan a manejar el dinero.',
        content: `<p>Abrir una cuenta de ahorro para niños es una gran forma de que tus hijos aprendan sobre cómo manejar correctamente el dinero.</p>
        <p>Desde muy pequeños ellos se hacen ideas sobre el dinero –buenas o malas– así que una cuenta de ahorro, junto con un buen ejemplo y lecciones al respecto, va a convertirse en una gran herramienta.</p>
        <p>Sin embargo, dejarle ya tomar a tu hijo decisiones sobre su cuenta de ahorro puede generarte ciertas dudas.</p>
        <p>¿Y si se lo gasta todo? ¿Aprenderá realmente para qué funciona la cuenta? ¿Qué sucederá si comete errores? Estas y otras preguntas pueden rondarte por la mente.</p>
        <p>Para que disipes estas dudas, te dejamos tres consejos para que esta cuenta de ahorro para niños se convierta en el gran instrumento financiero que tiene el potencial de ser.</p>
        <h2>Confía en tu hijo</h2>
        <p>No tengas miedo de que malgaste el dinero. Esto no va a pasar si tú le das el ejemplo y las lecciones necesarias para que no lo haga. Está comprobado que las actitudes que vea, así como lo que tú como padre le digas sobre el dinero, son dos de las experiencias definitorias para que tu hijo aprenda bien o mal a manejar sus finanzas.</p>
        <p>Habla con él de cómo funciona una cuenta de ahorro para niños.</p>
        <p>En el momento de darle la posibilidad de tomar decisiones sobre su cuenta de ahorro, es necesario explicarles varias cosas. Entre estas, para qué sirve, cómo usarla, cómo va a ganarse el dinero que se va a colocar ahí y la cantidad que va a destinar a su ahorro. Es recomendable que decidan un monto específico de domingo. Asimismo, necesitan acordar un porcentaje para gasto corriente y otro para que él lo ahorre.</p>
        <p>Ahorrar se le hará más fácil si él tiene una meta clara que quiere alcanzar, así que es importante que juntos establezcan una meta financiera.</p>
        <p>De todas formas, es importante darles la oportunidad de que ellos tomen decisiones.</p>
        <h2>No temas que se equivoque</h2>
        <p>Cometer errores es una excelente manera de aprender. Por ello, darles la oportunidad a los niños de que manejen su propio dinero es una manera efectiva para que generen experiencia.</p>
        <p>"Permíteles cometer errores para que eso pueda ayudarlos a aprender de ellos y ayúdelos a desarrollar hábitos antes de que se sientan solos, cuando las consecuencias sean mucho mayores y se enfrenten a grandes cantidades de dinero”, recomienda Ashley Lebaron, una estudiante de doctorado a cargo de un estudio de la Universidad de Arizona que demostró que la experiencia de los niños es fundamental.</p>
        <p>Es mejor que ellos se equivoquen ahora que son pequeños a que lo hagan de adultos. Si toman una mala decisión, es importante hablar de qué salió mal para evitar el mismo error la siguiente vez.</p>
        <h2>Dale un buen ejemplo</h2>
        <p>No tengas dudas de hablarle a tu hijo sobre la manera en la que has ahorrado para comprar el auto o la casa. Mantener estas conversaciones y reforzarlas con un comportamiento que vaya en consecuencia es la mejor manera en la que ellos van a aprender a usar bien el dinero.</p>
        <p>“Pequeños ojos te miran. Si estás usando el plástico cada vez que sales a cenar o al supermercado, ellos eventualmente lo notarán. O si tú y su cónyuge discuten sobre el dinero, también lo notarán”, <a href="https://www.daveramsey.com/blog/how-to-teach-kids-about-money" target="_blank">asegura Dave Ramsey</a>, uno de los especialistas en finanzas personales más famoso de Estados Unidos. El ejemplo es esencial para que ellos aprendan a usar de manera correcta su cuenta de ahorro.</p>
        <div class='orange-line'></div>
        <p class='bold'>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre?</p>
        <p>Descarga aquí la app Inverkids, te compartiremos el conocimiento necesario a ti y a tus hijos, para que aprendan a gestionar su dinero, establecer metas financieras y aumentar ahorros a través de la asignación de tareas y recompensas económicas.</p>
        <p>Y si quieres conocer más de nuestros programas, entra a <a class="link" href="www.inverkids.mx">www.inverkids.mx</a></p>`,
    },
    {
        id: 'coronavirus-aprovecha-y-enséñales-a-ahorrar-a-tus-hijos-en-casa',
        title: 'Coronavirus: Enséñales a ahorrar a tus hijos en casa',
        image: 'assets/images/blog/img10.jpg',
        description:
            'Ahora que estarán en casa por unos días debido al aumento de casos de coronavirus, ¡enséñales a ahorrar a tus hijos en casa! Te dejamos 6 formas de hacerlo.',
        content: `<p>Estos días de permanecer en casa debido a los casos de coronavirus que cada vez aumentan más en México pueden ser difíciles, sin embargo, es posible aprovecharlos para que los niños reciban grandes lecciones de finanzas: Aprovecha el momento y enséñales a ahorrar a tus hijos desde casa.</p>
        <p>Es posible usar estos momentos de crisis e incertidumbre para cambiar hábitos y para hacerlo en forma de juego con los niños. El objetivo será distraerlos del bombardeo diario de noticias y hacerlos sentir cómodos y protegidos en casa.</p>
        <p>En ese sentido, te dejamos 6 formas en las que aprender a ahorrar se convertirá en algo divertido y que podrá acercar a la familia a través de la convivencia.</p>
        <h2>Planeen todas las comidas y hagan la lista de supermercado juntos</h2>
        <p>Ahora que están en casa para evitar el contagio por coronavirus, una forma de entretenerse a la vez que reducen gastos es planear el desayuno, comida y cena de la semana que viene y hacer la lista de supermercado con base en los ingredientes que se van a necesitar. Esto hará que a la hora de comprar todo aquello que no esté en la lista quedará descartado y que los alimentos se compren en las cantidades correctas. Además de que puede ser una actividad divertida para los niños, evitará tanto el despilfarro de recursos y de comida.</p>
        <h2>Pongan horarios para ver la televisión…y si se atreven, ¡apáguenla!</h2>
        <p>Una exposición alta a la televisión además de que va a consumir electricidad –más porque ahora están en casa más tiempo– va a mantenernos expuestos al bombardeo comercial y noticioso de estos tiempos. No se trata de no estar informado, sino de informarse lo suficiente y no más. La principal recomendación de los especialistas para sobrellevar este encierro en casa debido a la pandemia del coronavirus es evitar la sobreinformación. En vez de mirar la televisión todo el tiempo, planeen jugar entre los miembros de la familia. ¿Qué necesitan? Solo papel y lápiz. Entre las sugerencias están el juego de ¡Basta!, Gato, Timbiriche, Ahorcado. Da <a href="http://mamaextrema.com/2016/09/23/10-juegos-con-papel-y-lapiz-2/" target="_blank">clic aquí</a> para conocer las instrucciones de cada uno.</p>
        <h2>En general, disminuyan el consumo de electricidad</h2>
        <p>Enséñales a ahorrar a tus hijos en casa ahora que están de confinamiento. Esto hará que ellos puedan cambiar también de hábitos y que todos juntos contribuyan a equilibrar los gastos de la casa. Y la electricidad es un rubro que puede hacer la diferencia.</p>
        <p>“La aplicación de medidas de ahorro, algunas de gran simplicidad, además de traducirse en importantes ahorros en el consumo, evita la emisión de millones de toneladas de contaminantes a la atmósfera”, explica una guía realizada por la Comisión Estatal de Energía de Baja California. Por el ambiente y por tu bolsillo, estos días de confinamiento son un gran momento para cambiar malos hábitos.</p>
        <p>Si no están usando una luz en alguna habitación, apágala. Aprovechen la luz natural en vez de prender la eléctrica. Si van a lavar ropa, usa ciclos de baja temperatura y sin prelavado y llénala lo más que puedas. En el caso de que no la llenes, utiliza algún ciclo de media carga. Evita usar la secadora y mejor sequen la ropa colgada. El refrigerador puede consumir <a href="http://www.guinotprunera.com/noticias/consejos-novedades/como-reducir-hasta-un-50-el-consumo-electrico/" target="_blank">hasta 30% del consumo eléctrico</a>, así que ábrelo solo cuando lo necesites y fíjate en cerrarla bien. Apaguen los electrodomésticos antes de terminar de usarlos para que se aproveche todo el consumo de energía. Los consejos son muchos y <a href="http://comisi%C3%B3n%20estatal%20de%20energ%C3%ADa%20de%20baja%20california/">esta guía realizada por la Comisión Estatal de Energía de Baja California</a> va a darte más información en este sentido.</p>
        <h2>Hagan limpieza de la casa y busquen cosas qué se puedan vender</h2>
        <p>En casa, solemos tener más cosas de las que realmente necesitamos. Y estos días de confinamiento por la pandemia del coronavirus nos pueden ayudar a reflexionar un poco al respecto, y quién sabe, si en cuanto termine la cuarentena nos pueda traer algo de dinero extra al venderlo. Recorran la casa y vayan analizando los artículos del hogar. ¿Están usándolos todos de la manera en la que deberían? Quizá hay artículos muy buenos que no están recibiendo el uso que deberían y que pueden venderse. Lo mismo en los clósets de todos los miembros de la familia. Probablemente haya alguna ropa que no se esté usando o que en realidad nunca nos gustó y solo está ahí ocupando espacio.</p>
        <p>¿Tienes duplicados artículos? Es decir, en vez de tener un juego de copas tienen tres o cuatro. O tienen dos planchas en vez de una. Consideren deshacerse de los duplicados.</p>
        <p>¿Tienen dudas de si van a necesitar o no todos estos artículos que van a retirar de la casa? Esta cuarentena es perfecta. Pónganlos en cajas y retírenlos de la vista. Cuando termine la cuarentena podrán analizar qué tanto se acordaron y necesitaron estos objetos y así podrán decidir ya con toda la convicción si venderlos o no.</p>
        <p>En estos tiempos de confinamiento por el coronavirus, hacer esta actividad con tus hijos será divertido y les enseñarás valores fundamentales en contra del consumismo.</p>
        <h2>Ahorra agua y enséñales a ahorrar a tus hijos en casa</h2>
        <p>Aunque el agua suele ser muy barata, ahorrarla hará un beneficio tanto al ambiente como a tu bolsillo, y ahora que están en casa todos, es momento de cambiar hábitos. Rieguen el jardín durante la noche para que no se evapore el agua (y queme las plantas), recojan en una cubeta el agua fría de la regadera que corre antes de que salga la caliente y úsenla para el excusado, no se rasuren ni cepillen los dientes en la regadera y cuando lo hagan fuera abran y cierren la llave cuando la usen. Tomen baños cortos y cuando laven los trastes, retiren los residuos orgánicos antes con la mano para evitar gastar agua. Más consejos para ahorrar agua <a href="http://www.conagua.gob.mx/CONAGUA07/contenido/documentos/Recomendaciones%20para%20ahorrar%20el%20agua.pdf" target="_blank">aquí</a>.</p>
        <div class='orange-line'></div>
        <p class='bold'>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre?</p>
        <p>Descarga aquí la app Inverkids, te compartiremos el conocimiento necesario a ti y a tus hijos, para que aprendan a gestionar su dinero, establecer metas financieras y aumentar ahorros a través de la asignación de tareas y recompensas económicas.</p>
        <p>Y si quieres conocer más de nuestros programas, entra a <a class="link" href="www.inverkids.mx">www.inverkids.mx</a></p>`,
    },
    {
        id: 'coronavirus-en-méxico-lecciones-de-dinero-para-niños',
        title: 'Coronavirus en México: Las 6 lecciones de dinero para niños en tiempo de crisis',
        image: 'assets/images/blog/img9.jpg',
        description:
            'La llegada del coronavirus a México ha supuesto para todos un cambio y muy probablemente, un reto económico. Aprovéchala para que ellos aprendan de dinero.',
        content: `<p>Desde hace unas semanas se detectaron los primero casos de coronavirus en México y hoy formamos parte de los países en los que se extiende la pandemia. El nuevo virus ha transformado nuestras vidas y nos ha llevado a tener a los niños en casa e incluso, si es posible, a trabajar desde ahí.</p>
        <p>Estamos pasando más tiempo del normal con nuestros hijos y la presencia del coronavirus en México puede ser una ocasión propicia para hablarles sobre el dinero. Ellos se hacen ideas sobre este –tanto buenas como malas– así que es importante ayudarlos a que se formen dentro de ellos conceptos que van a servirles para conformar su situación financiera en un futuro.</p>
        <p>Te dejamos varias lecciones que puedes enseñarle a tus hijos a propósito de estas épocas difíciles. La mayoría tiene que ver con el dinero. Sin embargo, hay dos que son valores que les permitirán afrontar la vida de una manera mucho más segura y con esperanza en el futuro.</p>
        <h2>La confianza que da un fondo de emergencia</h2>
        <p>Este es el momento propicio para hablarles de la necesidad de tener un fondo de emergencia. Este es un “colchón” de dinero que nos permite protegernos ante imprevistos. ¿Y si alguien necesita medicamentos o ir al doctor en estos tiempos? Pues este fondo puede ayudarnos a enfrentar estas eventualidades.</p>
        <p>El fondo de emergencia es, según consideran algunos expertos en finanzas personales, la base de unas finanzas sanas. Si lo tienes, podrás enfrentar que se te descomponga el coche o que alguien de la familia se enferme y tenga que recurrir a medicamentos y servicios médicos. De no tenerlo, una de las soluciones –por desgracia, más comunes– a la que se suele recurrir es al endeudamiento.</p>
        <p>Habla con ellos de la importancia de estar protegido. Plantéale escenarios y deja que él llegue a sus conclusiones. Después compártele lo que sería mejor en cada caso, de esta manera le ayudarás a hacerse de un conocimiento sobre el tema.</p>
        <h2>La tranquilidad que traen los seguros médicos</h2>
        <p>De la mano al fondo de emergencia, están la importancia de tener un seguro médico. Con este, en el caso de caer enfermos, toda la familia estaría protegida. Si tienes uno es un excelente momento de explicarle a tus hijos cómo funciona un instrumento de este tipo. Puede resultar complicado, pero basta con que le digas que un servicio que contrataste con una compañía, el cual consiste en que si te enfermas, ellos van a pagarte los gastos resultantes de ello. Por supuesto, a cambio de una contribución que haces cada mes.</p>
        <h2>La necesidad de tener un orden financiero</h2>
        <p>Estar en casa probablemente exija la necesidad de ajustar gastos, debido a una reducción del trabajo o a la necesidad de gastar más en alimentos y en despensa. Y es un buen momento para explicarle a los niños que es muy útil tener un presupuesto. Es decir, una lista de gastos e ingresos que se preven hacer un cierto periodo de tiempo. Muéstrales tu presupuesto y ponles ejemplos de cómo tuviste que reajustar los gastos pero de cómo, si no lo hubieras hecho, hubiera habido más riesgo de que tus finanzas se salieran de control.</p>
        <p>Por desgracia, en México, es común que las personas no lleven un presupuesto escrito y de manera constante. Si tú estás entre esas personas, ¡qué esperas! Este es un momento de ponerte manos a la obra y no dejar que tus finanzas se te salgan de las manos.</p>
        <h2>Lo importante que es ahorrar</h2>
        <p>Si no hubieras ahorrado, no tendrías un fondo de emergencia y si no hubieras tenido tus finanzas en orden a través de un presupuesto, no te hubiera permitido ahorrar. Aprovecha estos tiempos para hablarles de la importancia del ahorro. Este es una de las claves para tener unas finanzas sanas y es un hábito que se cultiva con el tiempo y la constancia. Habla con tus hijos y aprovechen este tiempo para que, si ellos no lo tienen, empiecen a generar su fondo de ahorro. Plantea escenarios y pregúntales qué piensan ellos que se puede lograr con un fondo de ahorro y trabajen en conjunto para <a href="https://inverkids.mx/blog/metas-financieras-para-lograr-lo-que-sue%C3%B1as" target="_blank">definir sus metas financieras</a> y trazar un camino para cumplirlas.</p>
        <p>Si eres de los que no está ahorrando nada, es un buen momento para que, cuando esta época de coronavirus en México se termine, pongas manos a la obra.</p>
        <h2>La solidaridad en momentos difíciles</h2>
        <p>Este es uno de los valores que no tiene que relacionarse necesariamente con dinero pero que es fundamental fomentar en nuestros hijos. Y estos tiempos son excelentes para ello. Quizá tú y tu pareja sean jóvenes, y en los niños, se ha demostrado que el coronavirus causa, a lo mucho, síntomas leves. Sin embargo, es importante fomentar en los niños este sentido de comunidad en el que estar pendientes del resto es cuidarnos también a nosotros mismos. Estar en casa, por ejemplo, va a permitir salvar las vidas no de los más jóvenes que tienen poco riesgo de enfermarse gravemente a causa del coronavirus, sino de los más ancianos, que son los más afectados. Este valor puede relacionarse también con el dinero. ¿Alguien de la familia necesita apoyo para comprar la despensa? ¿Los más mayores de la familia pueden ir por la compra o podemos ayudarles ¿Tiene la familia completa recursos para atenderse en el médico ante una posible enfermedad? Este momento de crisis es un momento de apoyar y de que los niños puedan aprender la importancia de hacerlo.</p>
        <h2>La necesidad de ser resilientes ante la adversidad</h2>
        <p>Esta es una cualidad que tampoco se relaciona directamente con el dinero pero que muchas veces es la clave para reponerse de momentos económicos difíciles. La resiliencia es aquella capacidad de reponerse ante los malos momentos y de buscar alternativas para solucionar las crisis. Así que si el coronavirus en México te trajo una mala situación económica, piensa que esto no será para siempre. Podrás reponerte y vendrán mejores momentos. Si esto se lo transmites a tu hijo él podrá sentirse más tranquilo de que tú lo protegerás y de que los males no serán para siempre.</p>
        <div class='orange-line'></div>
        <p class='bold'>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre?</p>
        <p>Descarga aquí la app Inverkids, te compartiremos el conocimiento necesario a ti y a tus hijos, para que aprendan a gestionar su dinero, establecer metas financieras y aumentar ahorros a través de la asignación de tareas y recompensas económicas.</p>
        <p>Y si quieres conocer más de nuestros programas, entra a <a class="link" href="www.inverkids.mx">www.inverkids.mx</a></p>`,
    },
    {
        id: 'explicación-para-niños-de-que-es-el-coronavirus',
        title: 'COVID-19: Una explicación para niños de qué es el coronavirus',
        image: 'assets/images/blog/img8.jpg',
        description:
            'Los niños están también preocupados por la pandemia, así que necesitas explicarles qué es el coronavirus y que tú estás para protegerlos. Te decimos cómo.',
        content: `<p>Como padres, es necesario elaborar una explicación de qué es el coronavirus para niños, ya que ellos también pueden estar preocupados o tristes por esta pandemia. Desde hace semanas la pandemia del coronavirus ocupa los titulares de los periódicos y transformó radicalmente nuestras vidas y la de nuestros hijos. Por ello, necesitamos hacerlos partícipes para que ellos entiendan la situación.</p>
        <p>Así como todos, ellos también se ven expuestos a un bombardeo de noticias e información sobre este fenómeno, a las pláticas entre familiares y a la información que circula en internet, lo que puede hacerlos vulnerables a sentimientos de ansiedad, estrés y tristeza, explica un documento al respecto de la Organización de las Naciones Unidas.</p>
        <p>Por ello, es necesario elaborar una explicación sobre qué es el coronavirus para niños. Eso sí, siempre con estas premisas bien claras, de acuerdo con los especialistas: Es necesario ser honestos, no minimizar el problema, no inventar respuestas si no se tienen y sin minimizar sus preocupaciones al respecto.</p>
        <p><b>Infórmate.</b> No lo necesitas saber todo pero sí conocer la información real y de fuentes oficiales. Debes ser el primero que deje de propagar rumores o de leer información que no esté confirmada. Si no lo haces, seguramente tus opiniones sin fundamento llegarán a los oídos de tus hijos. Esto, por supuesto, podrá alterarlos y angustiarlos más de lo que la propia situación propicia.</p>
        <p><b>Comienza con una pregunta.</b> Ahora bien. Pregúntale a tus hijos qué es lo que saben sobre el tema. Pídeles que te expliquen lo que saben, y tranquilízalos. Diles que es natural sentirse triste o asustarse, <a href="https://news.un.org/es/story/2020/03/1471422" target="_blank">recomienda la ONU</a>. Ellos reciben mucha información de distintos medios y luego ni siquiera nosotros somos conscientes de ello. Esto te ayudará a reconocer las fuentes de información de tu hijo, lo cual a su vez te permitirá eliminar las que no le estén proporcionando información fiable.</p>
        <p>Sobre todo si son pequeños, un dibujo o un apoyo gráfico puede ayudar mucho a que ellos entiendan la situación.</p>
        <p>La psicóloga Manuela Molina explica que las emociones infantiles se procesan a través del juego repetitivo y de los cuentos leídos varias veces. En este sentido, ella desarrolló un ebook que es posible descargarlo de manera gratuita. Este, más que estar lleno de información científica, explica el fenómeno desde los simbólico para que ellos puedan expresar sus emociones.</p>
        <p>Este tiene el objetivo de “acompañar el mundo emocional infantil” frente al coronavirus. 
        <a href="https://www.mindheart.co/descargables" target="_blank">Aquí</a> puedes descargar el ebook para que le expliques a tus hijos de manera gráfica.</p>
        <p>En este ebook, es una caricatura del coronavirus la que le explica a los pequeños qué es y cómo es probable que ellos se sientan al respecto.</p>
        <p><b>Sé honesto.</b> “Los niños tienen derecho a saber la realidad y estar informados, mientras que los adultos tienen la responsabilidad de protegerlos de las aflicciones”, explica la ONU. Diles la verdad pero refuerza su percepción de protección al dejarles claro que tú estás ahí para protegerlos de esta pandemia. Sin embargo, esta es la mejor oportunidad para que les expliques las medidas de higiene necesarias para que entre todos puedan luchar contra el virus. La principal, es lavarse las manos.</p>
        <p>Una forma en la que le puede explicar cómo funciona el contagio es pintándole las manos con plumón que se pueda lavar con agua. De esta manera pueden tocarte y entender cómo se propaga el virus, y así mismo entenderán la importancia de lavarse las manos.</p>
        <p>Si quieres apóyate en canciones o bailes que se han compuesto al respecto del coronavirus y que pueden medir el tiempo de un buen lavado de manos.</p>
        <p><b>Tranquilízalos.</b> Entre la verdad sobre esta pandemia está que mucha gente no está muriendo y está pasando la enfermedad con síntomas leves, así que cuéntales esta gran noticia. Esto va a ayudar a que se tranquilicen.</p>
        <p>“La ayuda a los hijos comienza con uno mismo. Si usted está tranquilo y en control de la situación ante las noticias sobre la enfermedad, los niños percibirán este tipo de respuesta”, explica la ONU.</p>
        <p>Cuida no sobrexponerlos a las noticias y jugar con ellos para distraerlos de esta realidad que estamos viviendo. Siempre haz hincapié en la necesidad de estar informados pero de fuentes fiables y verificables. Es importante que entiendan que no deben creer lo primero que digan las redes sociales.</p>
        <p>Recuérdale la labor como sociedad que estamos haciendo para combatir el virus, como lo es quedarse en casa. Hay también muchos profesionales de la salud alrededor del mundo que hoy están siendo los verdaderos héroes de esta pandemia y que con su entrega nos están protegiendo y cuidando para mitigar el daño.</p>
        <p>Cuéntale sobre ello. Esto le dará mayor tranquilidad y, seguramente, un sentido de esperanza.</p>
        <p>Quedémonos en casa si es posible y sigamos las recomendaciones para parar el contagio. Esto hará que contribuyamos a salvar vidas.</p>
        <p>Nosotros desarrollamos las habilidades financieras de tus hijos, pero en esta pandemia, estamos preocupados por tu bienestar y el de tus niños.</p>
        <div class='orange-line'></div>
        <p class='bold'>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre?</p>
        <p>Descarga aquí la app Inverkids, te compartiremos el conocimiento necesario a ti y a tus hijos, para que aprendan a gestionar su dinero, establecer metas financieras y aumentar ahorros a través de la asignación de tareas y recompensas económicas.</p>
        <p>Y si quieres conocer más de nuestros programas, entra a <a class="link" href="www.inverkids.mx">www.inverkids.mx</a></p>`,
    },
    {
        id: 'las-cuatro-razones-para-abrir-una-cuenta-de-ahorro-para-niños',
        title: 'Las 4 razones para abrir una cuenta de ahorro para niños',
        image: 'assets/images/blog/img7.jpg',
        description:
            'Una cuenta de ahorro para niños puede ayudarlos a entrar al sistema financiero más fácilmente y darles mejores perspectivas a futuro, según la ciencia.',
        content: `<p>Abrir una cuenta de ahorro para niños es algo que tienes que regalarle a tus hijos. Toques con ellos o no temas de dinero, los pequeños van haciéndose conceptos sobre este. Por ello, lo mejor es trabajar para que ellos se hagan la mejor idea posible y aprendan a tomar las mejores decisiones desde temprana edad.</p>
        <p>Justo un instrumento de este tipo es una de las mejores maneras de permitir que ellos aprendan tomando decisiones, mientras tú los guías en el camino.</p>
        <p>Así que si todavía tienes dudas, sigue leyendo. Te dejamos cuatro razones –comprobadas por la ciencia– que demuestran que abrir una cuenta de ahorro para niños es una de las mejores decisiones para su futuro.</p>
        <h2>La práctica hace al maestro</h2>
        <p>Que los niños tengan la posibilidad de tomar decisiones sobre el dinero y de practicar es una de las maneras más efectivas para inculcarles educación financiera. Estudios científicos al respecto como el realizado por científicos de la 
        <a href="https://mascdn.azureedge.net/cms/the-money-advice-service-habit-formation-and-learning-in-young-children-may2013.pdf" target="_blank">University of Cambridge</a> sostienen que los montos de dinero que los niños manejan influencian su aprendizaje sobre este.</p>
        <p>Así, una herramienta de este tipo es una excelente herramienta para lograrlo. Por supuesto, la cuenta por sí misma no es el único paso que hay que dar en este sentido. Es esencial que los padres acompañen esta tenencia de cuenta con conversaciones sobre cómo usarla y qué es posible lograr teniéndola.</p>
        <h2>Tener una cuenta de ahorro para niños ayuda a cambiar su visión de futuro</h2>
        <p>Aunque sea pequeña, una cuenta de ahorro para niños puede cambiar la manera en la que los pequeños ven su futuro. 
        <a href="https://theweek.com/articles/456111/3-reasons-need-open-savings-account-kids" target="_blank">William Elliott III, profesor asociado en la University of Kansas en Lawrence</a> y uno de los estudiosos más reconocidos en cuanto a cómo un instrumento de este tipo favorece a los pequeños para asistir a la universidad, explica que tenerla les hace generar una mentalidad de ahorro, lo cual los hace financieramente conscientes.</p>
        <p>En Estados Unidos, la deuda que los estadounidenses contraen debido a los préstamos para pagar la universidad se ha vuelto un problema. La investigación al respecto ha demostrado que tener una cuenta de ahorro no solo les cambia sus expectativas de vida, sino también la de los padres.</p>
        <h2>Entrar sistema financiero será más fácil para ellos</h2>
        <p>
        <a href="https://www.sciencedirect.com/science/article/pii/S0190740913000996" target="_blank">Un estudio publicado en el Children and Youth Services Review</a> demostró que aquellos niños que habían tenido una cuenta de ahorro tenían dos veces más probabilidad de tener tanto una cuenta de ahorro en la adultez como una tarjeta de crédito y que era cuatro veces más probable que tuvieran acciones en la bolsa de valores desde jóvenes.</p>
        <h2>Tener una es esencial para mejorar sus condiciones financieras a largo plazo</h2>
        <p>
        <a href="https://files.consumerfinance.gov/f/documents/bcfp_child-savings-accounts_report.pdf" target="_blank" rel="noopener noreferrer">Una investigación pionera en los noventas</a> demostró que la población de bajos recursos no solo necesitaba mejores ingresos para mejorar su condición a largo plazo, sino que también necesitaba acumular activos e invertir. Este análisis encontró que esto se lograba a través de una cuenta de ahorro. Es decir, que para mejorar nuestras condiciones a largo plazo, no solo es importante tener dinero suficiente, sino aprender a usarlo a través de metas financieras.</p>
        <div class='orange-line'></div>
        <p class='bold'>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre?</p>
        <p>En Inverkids te compartimos el conocimiento necesario para que tus hijos aprendan a gestionar su dinero, estableciendo metas financieras y ahorros a través de la asignación de tareas y recompensas económicas.</p>
        <p>Conoce más de nuestros programas en <a class="link" href="www.inverkids.mx">www.inverkids.mx</a></p>`,
    },
    {
        id: 'una-cuenta-de-ahorros-para-niños',
        title: '¿Una cuenta de ahorro para niños? Tienes que leer esto',
        image: 'assets/images/blog/img6.jpg',
        description:
            'Darles domingo, hablar con ellos de dinero y darles un buen ejemplo es solo el principio. Una cuenta de ahorro para niños puede ser el paso siguiente.',
        content: `<p>Puedes dudar entre abrirle o no una cuenta de ahorro a tus hijos, pero es hora de disiparlas. Una cuenta de ahorro para niños puede convertirse en una excelente herramienta para que ellos aprendan a manejar de manera correcta el dinero y desarrollen sus capacidades financieras.</p>
        <p>¿Por qué? Porque para aprender a manejar el dinero es necesario que ellos tengan acceso a este y que puedan tomar decisiones financieras al respecto. Incluso para que puedan equivocarse y aprender de ello.</p>
        <p>Darles domingo y acompañar esta actividad de educación financiera y de un buen ejemplo de los padres puede ser solo el principio. Una cuenta de ahorro para niños en la que ellos puedan ahorrar y administrar su dinero es el paso siguiente.</p>
        <p>“Guardar tu dinero en tu alcancía es un buen comienzo, pero hacerlo en el banco, a través de una cuenta de ahorro, te proporcionará la plena confianza de que tu dinero está seguro y además algunas te otorgarán rendimientos a largo plazo”, explica en un documento al respecto la 
        <a target='_blank' href="https://www.condusef.gob.mx/Revista/index.php/usuario-inteligente/servicios-financieros/780-cuentas-de-ahorro-para-ninos">Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (Condusef).</a></p>
        <p>Además de la seguridad que otorga tener el dinero en una cuenta de ahorro en alguna institución bancaria, ahorrar en una cuenta de ahorro desde niño fomenta la capacidad de ahorro, explica esta institución. Por supuesto, no sin la correcta guía de sus padres.</p>
        <h2>Una cuenta de ahorro para niños y adolescentes puede hacer la diferencia</h2>
        <p>Tres investigaciones de la Brown School de la Washington University en St. Louis demostraron que aquellos niños que tuvieron cuentas de ahorro tienen seis veces más probabilidades de asistir a la universidad.</p>
        <p>¿Por qué? Porque estos ejercicios demostraron que los ahorros tienen un efecto positivo más allá de los ahorros mismos. Es decir, que el hecho de tener una cuenta, implicaba tener metas financieras. Y esto, a su vez, cambiaba y mejoraba las expectativas tanto de los padres como de los niños.</p>
        <p>“Una cuenta de ahorro no es solo un vehículo para el ahorro sino un vehículo de esperanza”, 
        <a href="https://source.wustl.edu/2011/04/kids-with-savings-accounts-in-their-name-six-times-more-likely-to-attend-college/" target="_blank">explicaba en su momento</a> Arne Duncan, secretaria de educación en Estados Unidos al citar estos estudios como un ejemplo de la importancia de mejorar la educación financiera para alcanzar metas, como la de ir a la universidad.</p>
        <p>“Incluso una cuenta de ahorro pequeña para tu hijo puede hacer la diferencia para el futuro”, 
        <a href="https://www.consumerfinance.gov/about-us/blog/heres-why-you-should-open-a-savings-account-for-your-kids/" target="_blank">explica un texto del blog del Consumer Financial Protection Bureau de Estados Unidos.</a></p>
        <h2>Habla con ellos de finanzas</h2>
        <p>Sacarle una cuenta de ahorro para menores para tu hijo tiene que ir acompañada de una plática sobre por qué, para qué y qué puede lograr teniéndola. El objetivo es que esta se convierta en una herramienta para que él aprenda a manejar el dinero y a tomar sus propias decisiones financieras. Y para eso necesita tu acompañamiento, porque darles dinero solamente no es suficiente.</p>
        <div class='orange-line'></div>
        <p class='bold'>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre?</p>
        <p>En Inverkids te compartimos el conocimiento necesario para que tus hijos aprendan a gestionar su dinero, estableciendo metas financieras y ahorros a través de la asignación de tareas y recompensas económicas.</p>
        <p>Conoce más de nuestros programas en <a class="link" href="www.inverkids.mx">www.inverkids.mx</a></p>`,
    },
    {
        id: 'seis-claves-para-ensenar-finanzas-a-traves-del-domingo',
        title: 'Domingo: 6 claves para enseñarles finanzas a los niños',
        image: 'assets/images/blog/img4.jpg',
        description:
            'Aquellos que reciben mesada suelen manejar mejor el dinero, sin embargo, hay que hacer que esta actividad sirva para enseñarles finanzas a los niños.',
        content: `<p>Enseñarles finanzas a los niños empieza por el ejemplo, se complementa con las lecciones que les damos sobre el dinero y continúa con darles una mesada que les permita manejar el dinero y aprender de ello.</p>
        <p>
        <a href="https://www.sciencedirect.com/science/article/abs/pii/016748709190042R" target="_blank">Un estudio publicado en el Journal of Economic Psychology</a> demostró que aquellos niños que no recibieron mesada tendieron a gastar más dinero que los que sí la recibieron.</p>
        <p>En general, los estudios apuntan a que los que sí recibieron domingo suelen ser más ordenados en este sentido, aunque el simple hecho de recibir domingo no es sinónimo de que ellos van a aprender mejor a manejar el dinero.</p>
        <p>Por ello, hay que hacer que esta actividad se convierta en una forma en la que ellos aprendan a planear y tomar decisiones financieras.</p>
        <p>Pero, ¿cómo es la mejor manera de hacerlo?</p>
        <p>Te dejamos seis consejos para que darles domingo se convierta en una gran herramienta para enseñarle finanzas a los niños.</p>
        <h2>Hazlo lo más pronto posible</h2>
        <p>Está comprobado que desde pequeños los niños se forman una idea del dinero, hables o no con ellos de este tema. Por ello, es importante implementar esta práctica cuanto antes. “Lo importante es que los padres le permitan experimentar a sus hijos con el dinero desde épocas tempranas”, señala 
        <a href="https://www.sciencedaily.com/releases/2018/11/181127092523.htm" target="_blank">un estudio de la Universidad de Arizona</a>. 
        <a href="https://slate.com/business/2015/02/kids-allowances-youre-doing-it-completely-wrong.html" target="_blank">Ron Lieber, columnista del New York Times y autor del libro “The Opposite of Spoiled”</a>, asegura que la mejor edad para empezar es cuando ellos empiezan a desear cosas.</p>
        <h2>No se los des gratis</h2>
        <p>Darles dinero a cambio de ningún esfuerzo no hará que ellos crezcan con las lecciones suficientes para manejar el dinero. Está demostrado que los niños que crecen recibiendo mesada suelen ser más financieramente sanos que aquellos que no la reciben. Sin embargo, los pequeños necesitan aprender el valor del dinero, así que pueden doblar su ropa, vestirse por sí mismos, tender su cama o poner la mesa o recogerla. Las tareas podrán depender de su edad. Hacer esto ayudará a que ellos entiendan que el dinero tiene un valor y que tienen que trabajar para obtenerlo.</p>
        <h2>Establece reglas al respecto</h2>
        <p>Asegúrate de que ellos entiendan por qué están recibiendo su domingo, cómo van a obtenerlo y, en dado caso, cómo podrían perderlo, recomienda 
        <a href="https://www.aicpa.org/press/pressreleases/2019/childrens-allowance-pay-is-up-amount-saved-alarmingly-low.html" target="_blank">un estudio realizado por el Instituto Americano de Contadores Públicos</a>. Si quieres puedes establecer una base del monto que van a recibir y ofrecerles la oportunidad de ganar extras según la cantidad de tareas que realicen. Lo importante aquí es que ellos tengan claro todo lo relacionado con este tema.</p>
        <h2>Aprovecha para hablar de dinero</h2>
        <p>Este estudio realizado por este instituto encontró que de los padres que daban domingo a sus hijos, solo 
        <a href="https://www.aicpa.org/press/pressreleases/2019/childrens-allowance-pay-is-up-amount-saved-alarmingly-low.html" target="_blank">3% de ellos lo ahorraba</a>. Esto prueba que el simple hecho de darles dinero no es garantía de que ellos aprenderán a usarlo. Por ello, para enseñarles finanzas a los niños y adolescentes, es necesario que junto con el domingo venga una plática sobre el buen uso del dinero. Háblales de <a href="https://inverkids.mx/blog/metas-financieras-para-lograr-lo-que-sue%C3%B1as" target="_blank">la importancia de establecer metas financieras</a> y de ahorrar para estas, así como de la necesidad de tener un presupuesto si se quiere tener finanzas sanas.</p>
        <h2>Establece metas financieras con ellos</h2>
        <p>Para que aprendan la importancia del ahorro –y no se gasten la totalidad de su domingo– siéntate con ellos y háblales de lo 
        <a href="https://inverkids.mx/blog/trazar-metas-finaicieras-junto-a-tus-hijos" target="_blank">esencial que es tener metas financieras</a> y de la importancia del ahorro para ello. Estas son la clave para conseguir cualquier propósito financiero. Por ello, siéntate con ellos, pregúntales qué es lo que quieren lograr con su domingo y traza junto con ellos un plan para lograrlo. Es importante escribir este plan y que este sea lo más específico posible. De esta manera ellos podrán ir viendo que paso a paso van logrando pequeñas victorias.</p>
        <h2>Elabora un sistema de presupuesto sencillo</h2>
        <p>
        <a href="https://slate.com/business/2015/02/kids-allowances-youre-doing-it-completely-wrong.html" target="_blank">Lieber</a> recomienda dividir la mesada en tres categorías: ahorro, gastos y una parte para donar. El primero va destinado a cultivar su paciencia para lograr estas metas financieras planteadas. El segundo para gastarlo en aquellas cosas que les pueden dar alegría inmediata. En cuanto al tercero busca fomentar la generosidad a través de pequeñas donaciones que puedan ayudar a otros.</p>
        <div class='orange-line'></div>
        <p class='bold'>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre?</p>
        <p>En Inverkids te compartimos el conocimiento necesario para que tus hijos aprendan a gestionar su dinero, estableciendo metas financieras y ahorros a través de la asignación de tareas y recompensas económicas.</p>
        <p>Conoce más de nuestros programas en <a href="http://www.inverkids.mx">www.inverkids.mx</a></p>`,
    },
    {
        id: 'para-ensenarles-finanzas-a-tus-hijos-dales-domingo',
        title: 'Para enseñarle finanzas a tus hijos, dales domingo',
        image: 'assets/images/blog/img2.jpg',
        description:
            'Enseñarle finanzas a tus hijos no solo se trata de darles dinero. Necesitas guiarlos y hablar una y otra vez con ellos para que lo aprendan a hacer bien.',
        content: `
        <p>Si quieres enseñarle finanzas a tus hijos y que aprendan a manejar de manera correcta el dinero, es necesario que tengan el poder en sus manos de manejarlo.</p>
        <p>Que ellos tengan experiencia real es una herramienta muy poderosa que les ayuda a prepararse financieramente para la vida adulta, de acuerdo con un estudio publicado en el 
        <a href="https://journals.sagepub.com/doi/abs/10.1177/0192513X18812917?journalCode=jfia" target="_blank">Journal of Family Issues.</a> Y que les previene de tomar futuras malas decisiones.</p>
        <p>Así, darles domingo es una de las mejores formas en las que ellos pueden aprender el valor del dinero.</p>
        <p>Pero no se trata de dejarlos solos. Sino de orientarlos, permitirles cometer errores que puedan ayudarlos a aprender y de ayudarlos a desarrollar hábitos desde temprana edad. Es mejor hacerlo antes de que se tengan que enfrentar a la necesidad de manejar grandes cantidades de dinero, explica este análisis.</p>
        <p>
        <a href="https://business.time.com/2012/08/27/monkey-see-monkey-do-just-1-of-kids-save-any-allowance-money/" target="_blank">Un estudio del Instituto Americano de Contadores Públicos</a> demostró esto y que darles dinero y dejarlos sin guía es una de las peores decisiones.</p>
        <p>Solo 1% de los niños ahorró dinero de su asignación económica. Y los niños gastaron sus domingos comprando juguetes y saliendo con amigos.</p>
        <p>“Es tentador proteger a los niños de todo lo relacionado con el dinero, pero es muy importante que los padres pongan el dinero en sus manos desde el principio para que puedan practicar trabajar para ello, administrarlo y aprender a gastarlo de manera consciente”, explica la autora del estudio publicado en el Journal of Family Issues, Ashley LeBaron, en 
        <a href="https://www.sciencedaily.com/releases/2018/11/181127092523.htm" target="_blank">un documento sobre los resultados de su investigación.</a></p>
        <h2>Enséñale finanzas a tus hijos y guíalos en el proceso</h2>
        <p>La literatura al respecto sostiene que lo que aprenden los niños sobre el dinero principalmente viene del ejemplo y de lo que sus padres les dicen sobre este. Y estudios científicos han demostrado que darles una mesada y guiarlos en el camino es la mejor forma de poner todos estos conocimientos en práctica, desarrollando así sus capacidades financieras.</p>
        <p>
        <a href="https://www.aicpa.org/press/pressreleases/2019/childrens-allowance-pay-is-up-amount-saved-alarmingly-low.html" target="_blank">Otro estudio más reciente sobre el tema</a> elaborado por el mismo instituto de contadores públicos, sostiene que es necesario dejarles claro varios aspectos cuando a los niños se les da domingo. Entre estos, el por qué lo están recibiendo, qué pueden obtener con él y qué pueden perder.</p>
        <p>De esta manera, es importante aprovechar el momento en el que le damos el dinero a nuestros pequeños para hablar de su manejo. Cómo hacer un presupuesto, la importancia de la gratificación a largo plazo y de la importancia de ahorrar para una meta son temas que deberían tratarse con ellos.</p>
        <p>Pero no solo eso, sino que hay que hacerlo de manera constante. Para enseñarle finanzas a tus hijos es muy útil aprovechar las situaciones de la vida que se presten para hablar de dinero. Entre más frecuentemente lo hagas, ellos van a aprender mejor a manejarlo de manera correcta.</p>
        <div class='orange-line'></div>
        <p class='bold'>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre?</p>
        <p>En Inverkids te compartimos el conocimiento necesario para que tus hijos aprendan a gestionar su dinero, estableciendo metas financieras y ahorros a través de la asignación de tareas y recompensas económicas.</p>
        <p>Conoce más de nuestros programas en <a href="http://www.inverkids.mx">www.inverkids.mx</a></p>`,
    },
    {
        id: 'trazar-metas-finaicieras-junto-a-tus-hijos',
        title: 'Los 5 consejos para trazar metas financieras junto a tus hijos',
        image: 'assets/images/blog/img1.jpg',
        description:
            'La ciencia ha encontrado que hay una forma de trazar metas financieras para que sí se cumplan. Seguirla hará que tú y tus hijos cumplan lo que se imaginan.',
        content: `<p>Nuestra mente suele jugar en nuestra contra a la hora de hablar de dinero, pero, por fortuna, la ciencia ha encontrado que hay una forma de trazar metas financieras para que sí se cumplan.</p>
        <p>Trazar metas financieras y cumplirlas es la clave para llegar al lugar que quieres llegar. Esto hace que tus propósitos financieros tengan más posibilidades de cumplirse porque los hace más específicos. Por tanto, aumenta las probabilidades de que los cumplas.</p>
        <p>Los mexicanos no solemos tener buenos hábitos financieros. Sin embargo, es momento de romper el ciclo. Con nosotros mismos y con nuestros hijos. Empezar a hablar cuanto antes con los niños es la mejor forma de formar adultos con buenos hábitos financieros.</p>
        <p>Pero, ¿cómo trazar estas metas financieras?</p>
        <p>Brad Klontz, un psicólogo especializado en finanzas personales, realizó un estudio en el que encontró que siguiendo ciertos puntos –que tenían en cuenta nuestra psicología– las personas podían incrementar en 73% sus tasas de ahorro.</p>
        <p>“¿Cuál es la barrera entre tú y el éxito financiero? La buena noticia, es que probablemente seas tú”, sostiene el especialista y asegura, que nuestro éxito en el dinero, depende de nuestra psicología.</p>
        <h2>Establece tus metas financieras</h2>
        <p>¿Dónde quieres estar en cinco, diez o quince años? Ya que tengas alguna idea, visualízalo y escríbelo, sostiene Klontz en 
        <a target='_blank' href="https://www.youtube.com/watch?v=HniJUwUlK94">un video</a> donde explica cómo cumplir metas en siete pasos. Él recomienda que escribas tres metas que realmente te emocionen para que puedas llegar a cumplirlas.</p>
        <p>Algo similar tienes que hacer cuando te sientes con tus hijos. Necesitas averiguar primero qué es lo que ellos quieren para luego empezar a planear.</p>
        <p>“El primer paso es hablar con su hijo sobre un área que pueden mejorar. Estate abierto a sus ideas y no solo a lo que tú crees que necesitan mejorar”, recomienda 
        <a target='_blank' href="https://www.wthr.com/article/watch-goal-setting-your-kids-new-year">especialista en educación de la WTHR, una estación de televisión afiliada a la NBC</a>. Y asegura que, cualquier conversación que comience negativamente con lo que tú quieres en vez de con lo que ellos desean, no tendrá éxito.</p>
        <h2>Anótalas</h2>
        <p>Varios estudios demuestran que para trazar metas financieras escribirlas aumenta la posibilidad de cumplirlas. 
        <a target='_blank' href="https://www.huffpost.com/entry/achieve-your-goals-research-reveals-a-simple-trick-that-doubles-your-chances-for-success_b_8955318?guccounter=1&amp;guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&amp;guce_referrer_sig=AQAAACIi50VPcGa_5te-7-nVbLMPBVaDp1Hr3-8UzVsTxUGKGYew7LJ88TYmy2clKtCDfXdy2FYr4Hls0zyJz1k25vgU6ITOZlRH_mzpyILMF-CFjw4E2EEmNvcBq2mJBbmOHedeb0R7c1msb2wbjSieZ0HQm0Twhm2iSC68tJeT1TeW">Un análisis</a> que se enfocó en analizar el cumplimiento de metas –pero en el área deportiva– encontró que aquellos que no solo escribieron sus metas sino que especificaron detalles sobre el cómo y el cuándo las iban a cumplir lo lograron en más de 90% de las veces. Detállalas lo más posible y te acercarás a poder cumplirlas.</p>
        <p>En este sentido, con los niños es un caso similar. La especialista en educación recomienda escribir las metas y ponerlas en algún lugar muy visible, como tu habitación o el refrigerador. Esto hará mucho más probable que se cumplan.</p>
        <p>En este sentido, Klonz recomienda ponerles incluso nombre. “No hay nada más aburrido que una cuenta de ahorro quitándote la alegría. Necesitamos nombrar nuestras metas financieras con un nombre emocionante, que pueda traerte imágenes y que pueda moverte para que la cumplas”, sostiene el especialista.</p>
        <h2>Ponle fecha de cumplimiento</h2>
        <p>Necesitas ponerle una fecha porque de esta manera te sentirás más comprometido y con más claridad sobre cuándo se cumplirá.</p>
        <p>Si a tu cuenta de ahorro le nombraste “vacaciones a Europa”, necesitas ponerle “vacaciones a Europa en el 2020”, por ejemplo, señala el psicólogo.</p>
        <p>En este sentido, 
        <a target='_blank' href="https://www.condusef.gob.mx/Revista/index.php/usuario-inteligente/educacion-financiera/643-los-ninos-y-la-educacion-financiera">Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (Condusef) recomienda</a> establecer metas de ahorro a corto y mediano plazo con los niños y analizar el tiempo que llevará cumplir cada una. “Apóyalo y dale un estímulo. Por ejemplo, por cada 50 pesos que ahorre, tú aportas otros 50”, sostiene.</p>
        <h2>Dibuja tus metas financieras</h2>
        <p>Klontz asegura que quienes lo hicieron aumentaron en 73% su ahorro.</p>
        <p>Imagina que vuelves a kinder y dibújalas. Ya sea que hagas un collage o que las dibujes con tu propia mano. Este consejo es uno de los que serán más divertidos para los niños. Tómate el tiempo de sentarte con ellos para que dibujen aquello que quieren lograr. Será divertido y esto les acercará a ambos a donde quieren llegar.</p>
        <h2>Traza un plan para lograrlo</h2>
        <p>Ya que se tiene claro qué se quiere lograr y cuándo, ahora es necesario trazar una ruta de acción para lograrlo. Escríbelo también. ¿Cuáles son los pasos que tienes que seguir para lograr aquello que quieres? Escríbelos con fecha y monto y haz lo mismo con tus niños.</p>
        <p>Por ejemplo, si ellos quieren comprar una bicicleta o un juguete, el ahorro será importante y necesitan hacerlo de manera periódica y constante, explica la Condusef.</p>
        <p>“Hay que enseñarles a guardar una cantidad fija en el presupuesto, así podrán hacer cuentas y estimar en cuánto tiempo juntarán el dinero que necesitan para su meta.”, sostiene esta comisión.</p>
        <p>En este sentido, 
        <a target='_blank' href="https://www.linkedin.com/pulse/how-create-action-plan-achieve-your-goals-shawn-bandick/">Shawn Bandick</a>, un coach que se dedica a ayudarle a la gente a trazar sus metas, sostiene que en este camino es importante establecer “mini” metas que te encaminen a la meta grande.</p>
        <p>Si quieres ahorrar 100 mil pesos en un año, calcula cuánto tendrías que ahorrar cada mes y apégate a ello. Lo mismo sucede con tus hijos. Establezcan metas pequeñas dentro de la grande para ir monitoreando el progreso y celebren cuando vayan lográndolo.</p>
        <p>“¡Celebren cada paso en el camino!”, recomienda Jennifer Brinker, la especialista en educación de la WTHR.</p>
        <div class='orange-line'></div>
        <p class='bold'>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre?</p>
        <p>La educación financiera no es un lujo, es un derecho que todos merecemos y cambiará nuestro rumbo en el futuro que comienza hoy.</p>
        <p>Regístrate en <a href="inverkids.mx">Inverkids</a> y empieza a establecer metas adecuadas a la edad de cada uno de tus hijos y a ayudarles a trazar el camino para lograrlas. ¡Nuestra nueva herramienta es gratis!</p>
        <p>En Inverkids empoderamos financieramente a las nuevas generaciones. Si quieres conocer más más de nuestros programas ingresa en <a href="inverkids.mx">Inverkids</a></p>`,
    },
    {
        id: 'metas-financieras-para-lograr-lo-que-sueñas',
        title: 'Establecer metas financieras, esencial para lograr lo que sueñas',
        image: 'assets/images/blog/img5.jpg',
        description:
            'Establecer metas financieras hará que tus propósitos financieros tengan rostro, se sientan más cercanos y sean mucho más fáciles de cumplir.',
        content: `<p>Ahorrar, no endeudarse, tener un presupuesto. Prepararse para el retiro, enseñarle buenos hábitos financieros a tus hijos. Tener buenas finanzas personales se vuelve una tarea imposible sin un hábito clave: Establecer metas financieras.</p>
        <p>Por desgracia, los mexicanos no logramos mantener en orden nuestras finanzas. En seis de cada 10 mexicanos que caen en impagos, la mala administración financiera es la culpable, 
        <a target='_blank' href="https://www.elfinanciero.com.mx/mis-finanzas/esta-es-la-razon-por-la-que-los-mexicanos-terminan-endeudados">según cifras de la reparadora Resuelve tu Deuda</a>.</p>
        <p>Para ponerle remedio, tanto en nosotros, como en las generaciones futuras, establecer metas financieras es la clave. Siempre es un buen momento para empezar –y más hoy, ¡estamos empezando el año!– pero también para sentarnos con nuestros hijos para ayudarlos a que ellos aprendan a hacerlo por sí mismos.</p>
        <p>El ejemplo y lo que los padres les dicen a los niños sobre el dinero son las formas en las que ellos aprenden más sobre este tema –según sostienen varias investigaciones–, así que aprender a hacerlo de la manera correcta y darles el ejemplo son las dos claves para que ellos lo aprendan a hacer bien.</p>
        <p>No importa la edad que tengan tus hijos, si ellos ven que tú las trazas y actúas en consecuencia, ten por seguro que ellos lo incorporarán a su rutina financiera.</p>
        <h2>Trazarlas funciona</h2>
        <p>Siempre será mejor tenerlas a no hacerlo, porque esto hará que tus propósitos financieros tengan rostro, se sientan más cercanos y sean mucho más fáciles de cumplir.</p>
        <p>Ahorrar no se hará tan pesado si piensas en que lo usarás para un viaje a la playa y si trazas la meta en ese sentido, por poner un ejemplo.</p>
        <p>Hacerlo de esta manera hará que generes una cercanía emocional con estas metas y, que, en consecuencia, esto te ayude a cambiar tu conducta.</p>
        <p>
        <a target='_blank' href="https://www.onefpa.org/journal/Pages/OCT19-The-Sentimental-Savings-Study.aspx">Un estudio publicado en el Journal of Financial Planning</a> encontró que las tasas de ahorro aumentaban 73% entre aquellos que se habían involucrado emocionalmente con sus conductas de ahorro a través del establecimiento de metas financieras.</p>
        <p>Esto quiere decir que los participantes en el estudio no solo las enumeraron, sino que se visualizaron cuando ya las hubieran logrado, les pusieron nombre y fecha. Incluso las dibujaron.</p>
        <p>Trabajar con estas metas financieras de manera directa y emocional ayuda. Y en este camino, escribirlas ha demostrado que es una de las claves que aumenta dramáticamente la probabilidad de que las cumplamos.</p>
        <p>
        <a target='_blank' href="https://www.dominican.edu/dominicannews/study-highlights-strategies-for-achieving-goals">Una investigación realizada por la Dominican University of California</a> encontró que más de 70% de aquellos que no solo las escribieron, sino que además mandaron actualizaciones semanales a un amigo, informó del logro de la meta.</p>
        <p>En contraste, apenas 35% que se guardó su meta para sí mismo lo logró.</p>
        <p>Si sigues estos lineamientos y lo aplicas a tu propia experiencia, tus hijos lo aprenderán de manera mucho más sencilla.</p>
        <p>Es enero y este es un excelente tiempo de empezar. Cuando tus hijos te pidan algo la siguiente vez, intenta establecer metas con ellos y un camino para cumplirlas.</p>
        <div class='orange-line'></div>
        <p class='bold'>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre?</p>
        <p>La educación financiera no es un lujo, es un derecho que todos merecemos y cambiará nuestro rumbo en el futuro que comienza hoy.</p>
        <p>Regístrate en <a href="inverkids.mx">Inverkids</a> y empieza a establecer metas adecuadas a la edad de cada uno de tus hijos y a ayudarles a trazar el camino para lograrlas. ¡Nuestra nueva herramienta es gratis!</p>
        <p>En Inverkids empoderamos financieramente a las nuevas generaciones. Si quieres conocer más más de nuestros programas ingresa en <a href="inverkids.mx">Inverkids</a></p>`,
    },
    {
        id: 'hablar-de-dinero-con-niños',
        title: 'Hablar de dinero con los niños, una gran forma de empezar el año',
        image: 'assets/images/blog/img3.jpg',
        description:
            'Según varios estudios, ellos aprenden de finanzas de los padres más que de cualquier otra fuente, por lo que es esencial hablar de dinero con los niños ya.',
        content: `<p>No tengas dudas: Hablar de dinero con los niños es algo que tienes que hacer ya.</p>
        <p>Según los estudios sobre el tema, solo hay dos formas en las que los niños aprenden realmente de finanzas. La primera es del ejemplo y la segunda es de lo que los padres les dicen sobre el dinero.</p>
        <p>Los niños aprenden más de los padres sobre finanzas que de ninguna otra fuente, explica 
        <a target='_blank' href='https://www.sciencedaily.com/releases/2018/11/181127092523.htm'>un estudio de la Universidad de Arizona</a>, y forman actitudes acerca del dinero a edad muy temprana, se les hable o no de este aspecto de la vida.</p>
        <p>Por ello, es importante hablar de dinero con los niños porque de no hacerlo, ellos se forman ideas al respecto. Y estas podrían ser erróneas.</p>
        <p>
        <a target='_blank' href='https://news.umich.edu/spendthrift-or-tightwad-children-form-attitudes-about-money-at-young-age/'>Una investigación de la Universidad de Michigan</a> encontró que desde los cinco años los niños ya tienen distintas reacciones emocionales al gasto y al ahorro de dinero, los cuales, a su vez se traducían en comportamientos de gasto en la vida real.</p>
        <p>Además, esta investigación demostró que el comportamiento temprano respecto al gasto podría presagiar malas decisiones financieras más adelante en la vida.</p>
        <h2>Es mejor hablar de dinero con los niños cuanto antes</h2>
        <p>Así, es muy importante intervenir a tiempo para asegurarse de que la gente esté en la vía financiera correcta.</p>
        <p>De no hacerlo, el riesgo es que ellos entiendan mal los problemas financieros desde pequeños y puedan actuar mal de grandes, según encontró otra investigación, esta de la 
        <a target='_blank' href='https://www.sciencedaily.com/releases/2014/04/140429092549.htm'>Universidad Estatal de Carolina del Norte.</a></p>
        <p>“Los padres deben hacer un esfuerzo por hablar con sus hijos para asegurarse de que los niños no desarrollen ideas erróneas sobre las finanzas”, sostiene este análisis.</p>
        <p>Ahora bien, ¿de qué temas debes hablar con ellos? De todos los relacionados con el dinero. Tanto ahorro, gastos e ingresos –estos tres son de los que más hablan los padres, de acuerdo con esta investigación–, como de otros más complejos o incómodos. Entre estos tópicos, también aquellos sobre los que casi ningún padre toca, como inversiones y deudas.</p>
        <p>Sentarse con los niños y tener conversaciones de estos temas ayudará a que ellos se vayan formando conceptos exactos sobre cómo funciona el dinero.</p>
        <p>La educación financiera no es un lujo, es un derecho que todos merecemos y cambiará nuestro rumbo en el futuro que comienza hoy.</p>
        <div class='orange-line'></div>
        <p class='bold'>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre?</p>
        <p>En Inverkids empoderamos financieramente a las nuevas generaciones. Nosotros te ayudamos a tener la mejor conversación con tus hijos sobre este tema. Conoce más de nuestros programas en <a class='link' href="inverkids.mx">Inverkids</a></p>`,
    },
    {
        id: 'cuentas-de-ahorro-infantiles-en-mexico',
        title: 'Las cuentas de ahorro para niños que existen en México',
        image: 'assets/images/blog/fotoblog-14.jpg',
        description:
            'Una cuenta de ahorro para niños es una excelente herramienta para aprender a manejar el dinero. Conoce las opciones que existen en el mercado.',
        content: `<p>Una cuenta de ahorro para niños va a permitirle a tu hijo tener la posibilidad de tomar decisiones sobre el dinero. Además, <a target='_blank' href="https://inverkids.mx/blog/las-cuatro-razones-para-abrir-una-cuenta-de-ahorro-para-ni%C3%B1os">va a ser una herramienta que le va a permitir practicar</a>. Todo esto, han demostrado estudios científicos, va a ayudarle a aprender a manejar de manera correcta el dinero.</p>
        <p>Por supuesto, no sin que tú acompañes todo este proceso. Necesitas darle un buen ejemplo y hablar con él sobre cómo hacer un buen uso de los recursos.</p>
        <p>Una cuenta de ahorro es una herramienta que permite planificar para lograr las <a target='_blank' href='https://inverkids.mx/blog/metas-financieras-para-lograr-lo-que-sue%C3%B1as'>metas financieras</a> que te propongas. Es importante tener metas financieras porque esto hará que camines con rumbo a la hora de acumular patrimonio.</p>
        <p>Por ello, cuando adquieran una cuenta de ahorros para niños, siéntate con tu hijo y ayúdale a trazar sus propias metas. En este <a target='_blank' href='https://inverkids.mx/blog/metas-financieras-para-lograr-lo-que-sue%C3%B1as'>link</a> te decimos cómo hacerlo a detalle.</p>
        <p>Hacer esta planeación hará que estas metas tengan rostro, las sientan más cercanas y las puedan cumplir más fácilmente.</p>
        <p>Las cuentas de ahorro para niños son instrumentos diseñados especialmente para estos propósitos, así que no tienen comisiones ni por apertura, manejo de cuenta o saldo mínimo. Todos los bancos las suelen tener, así que revisa primero con tu banco las condiciones de este tipo de herramientas para niños.</p>
        <p>Pero no por ello dejes de echarle un vistazo al resto de opciones que existen en el mercado. Hay algunas que te ofrecen seguros adicionales sin costo si mantienes cierto saldo o que te permiten obtener rendimientos a través de la inversión en activos, que es el caso de la cuenta de AFORE niños y de los Cetesdirecto niños.</p>
        <p>Te dejamos una lista de las cuentas de ahorro para niños que existen en México.</p>

        <p><b>Cuenta Junior Santander</b>
        <ul><li>Características principales: Tarjeta de débito para el titular y el cotitular, acceso a banca en línea y banca móvil, retiro gratis en cajeros automáticos y posibilidad de domiciliar los servicios.</li>
        <li>Requisitos: Identificación oficial vigente del padre, madre o tutor, comprobante de domicilio no mayor a tres meses y acta de nacimiento del menor de edad.</li>
        <li>Comisiones: Sin monto de apertura, no es requerido ningún saldo promedio mensual pero sí cobra la consulta de movimientos en cajeros de otros bancos. Si quieres revisar las comisiones más a detalle, entra <a target='_blank' href='https://www.santander.com.mx/PDF/Comisiones/PFCHQ.pdf'>aquí.</a></li></ul>
        <p>Más información sobre el producto <a target='_blank' href='https://www.santander.com.mx/personas/cuentas/junior.html'>aquí.</a></p>
        <p><b>Link Card BBVA Bancomer</b>
        <ul><li>Características principales: Es posible escoger el nombre y diseño de la tarjeta al contratarla por <a target='_blank' href='http://bbva.mx/'>bbva.mx</a> y recibir la tarjeta en el domicilio. Si tu hijo tiene celular, podrá pedirte dinero para que se lo envíes a través de la app BBVA Send.</li>
        <li>Requisitos: Tener una cuenta en BBVA que permita depósitos ilimitados al mes, ser padre o madre del menor (el cual debe tener menos de 18 años) y copia certificada del acta de nacimiento del menor en formato digital.</li>
        <li>Comisiones: Sin cobro de comisiones por apertura, manejo de cuenta o por no mantener un saldo mínimo. <a target='_blank' href='https://www.bbva.mx/content/dam/public-web/mexico/documents/personas/cuentas/link_card_comisiones_0518.pdf'>Aquí</a> más detalles de los conceptos que sí generan comisión.</li></ul>
        <p>Más información sobre el producto <a target='_blank' href='https://www.bbva.mx/personas/productos/cuentas/link-card.html'>aquí.</a></p>
        <p><b>Banco Azteca Guardadito Kids</b>
        <ul><li>Características principales: Puedes personalizar la tarjeta con tu nombre y stickers, además de manejarla desde la app.</li>
        <li>Requisitos: El monto mínimo de apertura es de 1.00 peso. Identificación oficial vigente del padre, madre o tutor, acta de nacimiento original del menor, comprobante de domicilio no mayor a tres meses de antigüedad,</li>
        <li>Comisiones: Sin comisiones por manejo de cuenta ni saldos mínimos, pero hay algunos conceptos como la impresión de un estado de cuenta adicional que sí te los cobran, <a target='_blank' href='https://www.bancoazteca.com.mx/content/dam/azteca/docs/producto/cuenta/guardadito-kids/200123/folleto-informativo-guardadito-kids.pdf'>aquí</a> lo puedes revisar.</li></ul>
        <p>Más información sobre el producto <a target='_blank' href='https://www.bancoazteca.com.mx/productos/cuentas/guardadito-kids.html'>aquí.</a></p>
        <p><b>Scotibank: Scotia Kids (niños) y Scotia Cool (jóvenes)</b>
        <ul><li>Características principales: Puedes disponer sin costo de dinero en cajeros Inbursa, BanBajio, Afirme, BanRegio, Banca Mifel, Intercam, Banco Famsa y Mi Banco Autofin y en Bancoppel y Multiva con comisión preferencial. La cuenta ofrece un seguro de vida por muerte accidental sin costo para el padre o tutor si el saldo promedio mínimo mensual es de 3,000 pesos. Permite establecer límites máximos de disposición en la tarjeta. Además, puedes depositar en la cuenta vía telefónica y por internet. Ofrece la posibilidad de tener una inversión a plazo sin contrato adicional.</li>
        <li>Requisitos: Identificación oficial vigente del adulto con firma y fotografía, comprobante de domicilio del adulto, CURP (Constancia de la Clave Única de Registro de Población del adulto expedida por la Secretaría de Gobernación u otro documento oficial en donde aparezca) y el acta de nacimiento del niño, el cual tiene que ser menor de 8 años.</li>
        <li>Comisiones: El monto mínimo de apertura es de 500 pesos. No tiene comisiones ni por apertura, ni por saldo promedio mínimo ni por manejo de cuenta. Tiene algunas comisiones por retiro de dinero en el extranjero que las puedes consultar <a target='_blank' href='https://scotiabankfiles.azureedge.net/scotia-bank-mexico/spanish/pdf/acerca-de/comunicados-al-publico-en-general/CuotasyComisiones/Tarifas-y-Comisiones-Scotia-Kids.pdf?t=1582642288464'>aquí.</a></li></ul>
        <p>Más información del producto<a target='_blank' href='https://www.scotiabank.com.mx/Personas/Cuentas-Bancarias/Ninos-y-Jovenes/scotia-kids.aspx'> aquí</a>.</p>
        <p><b>Scotia Cool</b></p>
        <ul><li>Características principales: permite consultar el saldo y disponer efectivo sin comisión en cajeros de Inbursa, BanBajio, Afirme, BanRegio, Banca Mifel, Intercam, Banco Famsa y Mi Banco Autofin. Además de poder hacerlo con comisión preferencial en la red de cajeros Bancoppel y Multiva. Permite retirar efectivo sin costo al pagar compras, controlar las operaciones en línea y recibir alertas sin costo por SMS y por correo electrónico. Este producto además ofrece un seguro de vida por muerte accidental sin costo para el padre o tutor si el saldo promedio mensual es mayor a 3 mil pesos. Es posible depositar vía telefónica, en línea y de forma automática a través de una programación previa. Ofrece intereses sobre los ahorros.</p>
        <li>Requisitos: Es para jóvenes entre ocho y 18 años, es necesario llevar la identificación oficial vigente del adulto con firma y fotografía, el comprobante de domicilio del adulto y su CURP, además del acta de nacimiento del menor.</p></ul>
        <p>Más información del producto <a target='_blank' href='https://www.scotiabank.com.mx/Personas/Cuentas-Bancarias/Ninos-y-Jovenes/scotia-cool.aspx'>aquí</a>.</p>
        <p><b>Citibanamex: Mi Cuenta Citibanamex</b></p>
        <ul><li>Características principales: Ofrece un seguro llamado Seguro Futuro Escolar, el cual consiste en un apoyo económico que se le da al menor en caso de fallecimiento del representante legal, si el saldo promedio mínimo de la cuenta es de 5,000 pesos (si quieres leer la póliza da clic <a target='_blank' href='https://www.banamex.com/resources/pdf/es/personas/cuentas/Condiciones_Futuro_Escolar_Seguro.pdf'>aquí</a>.</li>
        <li>Requisitos: Comprobante de domicilio, identificación oficial vigente del padre o tutor, acta de nacimiento o pasaporte del menor, el cual tiene que ser menor de 12 años.</li>
        <li>Comisiones: El monto mínimo de apertura es de 300 pesos. Sin comisiones por manejo de cuenta, pero sí te cobra por consultar los movimientos en los cajeros automáticos y por otra amplia variedad de conceptos. Revisa a detalle las comisiones <a target='_blank' href='https://www.banamex.com/resources/pdf/es/ley_transparencia/comisiones/cuentas-para-menores.pdf'>aquí</a>.</li></ul>
        <p><b>Banorte: Suma Menores</b></p>
        <p>Características principales: Paga intereses a partir de 500 pesos de saldo promedio mínimo mensual; a partir de un saldo promedio mayor a 1,000 pesos mensuales se recibe el estado de cuenta en el domicilio y es posible hacer consultas de saldos y movimientos a través de la banca en línea y por teléfono.</p>
        <p>Requisitos: Monto mínimo de apertura de 500 pesos. Acta de nacimiento del menor, identificación oficial vigente con fotografía y firma del padre, madre o tutor.</p>
        <p>Comisiones: No se necesita mantener saldo mínimo ni cobra comisión por manejo de cuenta. <a target='_blank' href='https://www.banorte.com/wps/wcm/connect/banorte/2fa57c2f-f42a-49c5-80f5-cac52c2ef0aa/Folleto+Informativo+SUMA+MENORES.pdf?MOD=AJPERES&ContentCache=NONE&CACHEID=2fa57c2f-f42a-49c5-80f5-cac52c2ef0aa'>Aquí</a> puedes consultar las comisiones que aplican.</p>
        <p>Más información del producto <a target='_blank' href='https://www.banorte.com/wps/wcm/connect/banorte/2fa57c2f-f42a-49c5-80f5-cac52c2ef0aa/Folleto+Informativo+SUMA+MENORES.pdf?MOD=AJPERES&ContentCache=NONE&CACHEID=2fa57c2f-f42a-49c5-80f5-cac52c2ef0aa'>aquí</a>.</p>
        <p><b>HSBC Flexible Menores</b></p>
        <ul><li>Características principales: Permite la consulta de saldos y retiro de efectivo sin costo hasta por 7,000 pesos. Es posible manejarla a través de una app. Recibes alertas SMS o vía e-mail por compras y retiros mayores a 1,500 pesos.</li>
        <li>Requisitos: Identificación oficial vigente, comprobante de domicilio, acta de nacimiento del menor o resolución judicial que demuestre la designación y aceptación del cargo del tutor.</li>
        <li>Comisiones: El depósito inicial es de 1.00 peso. No cobra comisiones por manejo de cuenta ni por saldo promedio. Si quieres descargar el folleto de comisiones, entra <a target='_blank' href='https://www.hsbc.com.mx/cuentas/productos/cuenta-flexible-menores/'>aquí</a>.</li></ul>
        <p>Más información del producto <a target='_blank' href='https://www.hsbc.com.mx/cuentas/productos/cuenta-flexible-menores/'>aquí</a>.</p>
        <p><b>Cuenta Afore Niños</b></p>
        <ul><li>Características principales: Necesitas registrar a tu hijo en la AFORE en la que estás. Lo puedes hacer a través de la app de Afore Móvil, necesitas capturar el CURP del pequeño, tomarle una fotografía a su acta de nacimiento y aceptar los contratos.</li>
        <li>Como madre, padre o tutor del niño, también puedes acudir a la AFORE que administra tu retiro y registrar al niño. A partir de ese momento podrán aportar a través de la ventanilla de las AFORE, domiciliación (a partir de 1.00 peso), en tiendas de conveniencia como 7-Eleven o vía celular. Es posible revisar el seguimiento de estos recursos en un estado de cuenta que se enviará tres veces por año o en cualquier momento desde la app. Ese ahorro se puede retirar en cualquier momento en la oficina de la AFORE.</li>
        <li>Requisitos: Identificación oficial del padre, acta de nacimiento o pasaporte del niño y, si es el caso, un documento que acredite la tutela del menor.</li>
        <li>Comisiones: Las mismas que se le cobren al ahorro voluntario en la AFORE en la que se está registrado.</li></ul>
        <p>Mas información del producto <a target='_blank' href='https://www.gob.mx/consar/articulos/cuenta-afore-ninos'>aquí</a>.</p>
        <p><b>Cetesdirecto niños</b></p>
        <ul><li>Características principales: Con una cuenta y a través de la plataforma de Cetes, el niño podrá invertir en valores gubernamentales sin la intermediación de la banca, casas de bolsa u otras instituciones, tal como una cuenta de Cetes.</li>
        <li>Requisitos: Necesitas ser titular de una cuenta de Cetes, así podrás abrirle una cuenta al niño. Necesitas su CURP para poder registrarlo y abrirle su cuenta.</li>
        <li>Comisiones: No tiene comisiones.</li></ul>
        <p>Más información del producto <a target='_blank' href='https://www.cetesdirecto.com/sites/portal/cetesdirectoninos'>aquí</a>.</p>
        <div class='orange-line'></div></p>
        <p>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre? Descarga aquí la app Inverkids, te compartiremos el conocimiento necesario a ti y a tus hijos, para que aprendan a gestionar su dinero, establecer metas financieras y aumentar ahorros a través de la asignación de tareas y recompensas económicas.</p>
        <p>Y si quieres conocer más de nuestros programas, entra a <a target='_blank' href='http://www.inverkids.mx'>www.inverkids.mx</a></p>`,
    },
    {
        id: 'diferencia-entre-deseos-y-necesidades',
        title: 'Enseñarle finanzas a los niños: La diferencia entre deseo y necesidad',
        image: 'assets/images/blog/fotoblog-15.jpg',
        description:
            'Conocer esta diferencia es la clave para que puedan tener en un futuro una relación sana con el dinero. ¡Empieza a enseñarle finanzas a los niños ya!',
        content: `
        <p>¿Lo necesito o lo quiero? Entender la diferencia entre deseo y necesidad es esencial a la hora de educarnos financieramente y de enseñarle finanzas a los niños.</p>
        <p>Mientras que una necesidad es eso que es muy importante y sin lo que muy probablemente no vamos a poder sobrevivir, un deseo es algo que sería muy bueno tener, pero sin él podemos vivir perfectamente.</p>
        <p>Es muy importante entenderlo nosotros y trabajar para que ellos también lo comprendan. De esta manera aprenderán a tomar decisiones con base en este criterio. A su vez, esto hará la diferencia a la hora de formar adultos que sean capaces de alcanzar la libertad financiera, un concepto clave en las finanzas personales.</p>
        <p>“Si hay un concepto clave para conseguir la libertad financiera es entender la diferencia entre lo que una persona quiere y lo que necesita”, <a target='_blank' href='https://preahorro.com/blog/como-ensenar-a-tu-hijo-a-ser-libre-financieramente/#Austeridad_necesidad_frente_a_deseo'>explica en su blog</a> Luis Pita, especialista en finanzas personales y autor del libro “Ten peor coche que tu vecino”.</p>
        <p>La libertad financiera es un concepto clave a la hora de hablar de finanzas personales. Pita lo define como el número de meses que podrías mantener tu actual ritmo de vida si hoy dejaras de ganar dinero. Es fundamental trabajar para que nuestra libertad financiera crezca, ya que hacerlo implicará que tienes un “colchón” muy amplio de dinero. Esto te permitirá no solo dormir tranquilo sino trabajar por esos proyectos que cuesta arrancar, como un cambio de carrera, por ejemplo.</p>
        <p>Entender la diferencia entre necesidad y deseo les hará convertirse en adultos que toman decisiones responsables con sus finanzas y que son capaces de alcanzar todo aquello que sueñan.</p>
        <p>¿Cómo enseñarle a los niños esta diferencia? Acompañándolos en el proceso de toma decisiones y dejándolos tomar la última palabra, sostiene Liz Frazier <a target='_blank' href='https://books.google.com.mx/books?id=qnahDwAAQBAJ&pg=PA57&lpg=PA57&dq=lisa+bullard+needs+and+wants+children+finance&source=bl&ots=r-C-43wku4&sig=ACfU3U0vFcUFNxXcnjmThvc-z0vAwGESAQ&hl=es&sa=X&ved=2ahUKEwiY34_859nnAhUFKawKHZ3fA2QQ6AEwCXoECAoQAQ#v=onepage&q=lisa%20bullard%20needs%20and%20wants%20children%20finance&f=false'>en el libro “Más allá de las alcancías y los puestos de limonada”</a>.</p>
        <p>La toma de decisiones es una de las habilidades más importantes que los niños necesitan desarrollar. Por esto ellos necesitan practicar a la hora de tomar decisiones, para que puedan aprender de sus errores. Si ellos se equivocan van a poder aprender y hacerlo mejor la siguiente vez.</p>
        <p><b>Utiliza el domingo para enseñarle finanzas a los niños</b></p>
        <p>Otro gran momento para hacerles ver esta diferencia es a la hora de <a target='_blank' href='https://inverkids.mx/blog/seis-claves-para-ensenar-finanzas-a-traves-del-domingo'>darle su domingo</a>. El especialista en finanzas personales, Luis Pita, recomienda ver este domingo como una transferencia de responsabilidades. Ellos se encargarán de administrarlo y de decidir qué es lo que necesitan, qué es lo que quieren y con qué intensidad.</p>
        <p>No le compres golosinas, juguetes o videojuegos, sino que haz que él sea el que las tenga que pagar. De esta manera aprenderá a manejar un presupuesto y a valorar qué necesita, qué quiere y con qué grado de intensidad lo desea, explica el experto en su blog.</p>
        <p>En el supermercado, en la casa, a la hora de comer fuera. Todos son grandes momentos para enseñarle finanzas a los niños.</p>
        <div class='orange-line'></div>
        <p>¿Quieres que tus hijos desarrollen sus valores y habilidades financieras de una forma divertida y para siempre? Descarga aquí la app Inverkids, te compartiremos el conocimiento necesario a ti y a tus hijos, para que aprendan a gestionar su dinero, establecer metas financieras y aumentar ahorros a través de la asignación de tareas y recompensas económicas.</p>
        <p>Y si quieres conocer más de nuestros programas, entra a <a target='_blank' href='http://www.inverkids.mx/'>Inverkids </a></p>`,
    },
];
