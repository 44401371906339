import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { UserProfileService } from '../../shared/services/userProfile.service';

import { AppLoginService } from './app-login.service';
import { SharedPopupComponent } from '../../shared/components/shared-popup/shared-popup.component';
import * as appDialogs from './app-login.dialogs';
import { UserCredentials } from '../../shared/models/user.model';
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ResetPasswordService } from '../../homepage/reset-password/reset-password.service';
import { show_parent_onboarding_key } from "../../modules/dashboard/student/student.component";

@Component({
    selector: 'app-app-login',
    templateUrl: './app-login.component.html',
    styleUrls: ['./app-login.component.scss'],
})
export class AppLoginComponent {
    pin = '';
    error;
    enrollmentId: string;
    forgot_enrollmentId: string;
    password: string;
    enrollmentIdError = false;
    passwordError = false;
    userCredentials = new UserCredentials();
    selected_lang = "es";
    forgot_pass = false;
    @ViewChild('pinInput', { static: false }) pinInput;

    constructor(private pinService: AppLoginService, private router: Router, private dialog: MatDialog,
        private userProfileService: UserProfileService,
        private activatedRoute: ActivatedRoute,
        private resetPasswordService: ResetPasswordService,
        private authService: AuthenticationService,
        ) {
    }

    goToLobby(): void {
        this.router.navigate(['/app-lobby']);
    }

    setEnrollmentId(event) {
        this.enrollmentId = event.target.value;
    }

    setForgotEnrollmentId(event) {
        this.forgot_enrollmentId = event.target.value;
    }

    setPassword(event) {
        this.pin = event.target.value;
    }

    toggleForgot() {
        this.forgot_pass = !this.forgot_pass;
    }

    login(): void {
        this.enrollmentIdError = false;
        this.passwordError = false;

        if (this.enrollmentId === "") {
            this.enrollmentIdError = true;
        }

        if (this.pin === "") {
            this.passwordError = true;
        }
        if (this.pin && this.enrollmentId) {
            this.userCredentials.enrollment_id = this.enrollmentId.trim();
            this.userCredentials.password = this.pin.trim();
            this.authenticateUser();
        }
    }

    sendEmail(): void {
        this.resetPasswordService.resetPasswordMail(this.forgot_enrollmentId.toLowerCase().trim()).then(
            (data) => {
                console.log("response: ", data);
            },
            (error) => {
                console.log("error: ", error);
            }
        );
    }

    authenticateUser(): void {
        this.authService.authenticateUser(this.userCredentials).then(
            async (user) => {
                this.userProfileService.setUserType(user.user_type);
                this.userProfileService.setUserRole(user.role);
                this.userProfileService.setUserId(user.id);
                this.userProfileService.setAuthToken(user.auth_token);
                this.userProfileService.setAccountType(user.account_type);
                this.userProfileService.removeParentToken();
                await this.userProfileService.getUserProfile();
                console.log(this.userProfileService.getLang());

                if (user.user_type === 'Parent') {
                    this.userProfileService.setParentToken(user.auth_token);
                    this.router.navigate(['/users']);
                } else if (['Teacher', 'Coordinator', 'Director'].includes(user.user_type)) {
                    this.router.navigate(['/school']);
                } else {
                    this.router.navigate(['/dashboard']);
                }

                localStorage.setItem(show_parent_onboarding_key, 'true');
            },
            (error) => {
                if (error.status === 400) {
                    this.error = error.error;
                    this.dialog.open(SharedPopupComponent, appDialogs.WRONG_PASS(this));
                }
            }
        );
    }
}
